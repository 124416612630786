import axios from 'axios'
import moment from 'moment';
import { call, all, takeEvery, put } from 'redux-saga/effects';
import URL, { localStorageConfig } from '../../settings';
import actions from './actions';



export function* getTotalAmount() {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Report/GetTotalAmountClaimReport/2022', config)

        yield put({
            type: actions.UPDATE_TOTAL_AMOUNT,
            reportDetail: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        alert(e);
        //yield put({
        //    type: actions.UPDATE_TOTAL_AMOUNT,
        //    reportDetail: [],
        //    loading: false,
        //    isError: true,
        //    errorMsg: "No connection. Please contact IT.",
        //});
    }
}


export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.GET_TOTAL_AMOUNT, getTotalAmount),
        
    ]);
}
