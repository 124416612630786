import axios from 'axios'
import moment from 'moment';
import { call, all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import URL, { localStorageConfig } from '../../settings';
import actions from './actions';

export function* getUploadedDocument({ id }) {
    if (id != undefined) {
        try {
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetUploadedDocument/' + id, config)
            yield put({
                type: actions.UPDATE_UPLOADED_DOCUMENT,
                documents: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_UPLOADED_DOCUMENT,
                documents: [],
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}
export function* deleteUploadedDocument({ id, detailID, claimID, obj, callback }) {
    if (id != undefined) {
        try {
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.delete, URL.apiUrl + 'TransportHeader/DeleteDocument/' + id + "/" + localStorage.getItem(localStorageConfig.curUserGUID), config)

            //const statusResult1 = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetUploadedDocument/' + formID, config)
            yield put({
                type: actions.UPDATE_UPLOADED_DOCUMENT,
              //  documents: statusResult1.data,
                loading: false,
                isError: false,
                errorMsg: "",
            });         

        }
        catch (e) {
            yield put({
                type: actions.UPDATE_DELETE_UPLOADED_DOCUMENT_STATUS,
                deleteStatus: "",
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
        callback(detailID, claimID, obj);
    }
}

export function* downloadUploadedDocument({ id }) {
    if (id != undefined) {
        try {

            //axios({
            //    url: URL.apiUrl + 'TransportHeader/DownloadFile2/' + id.id, //your url
            //    method: 'GET',
            //    responseType: 'blob', // important
            //}).then((response) => {
            //    const url = window.URL.createObjectURL(new Blob([response.data]));
            //    const link = document.createElement('a');
            //    link.href = url;
            //    link.setAttribute('download', id.fileName); //or any other extension
            //    document.body.appendChild(link);
            //    link.click();
            //});
        }
        catch (e) {
           
        }
    }
}

export function* previewUploadedDocument({ id }) {
    if (id != undefined) {
        try {

            axios({
                url: URL.apiUrl + 'TransportHeader/DownloadFile2/' + id, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('img');
                link.src = url;
                document.body.appendChild(link);
            });
        }
        catch (e) {
          
        }
    }
}

export default function* rootSaga() {    
    yield all([
        yield takeEvery(actions.GET_UPLOADED_DOCUMENT, getUploadedDocument),
        yield takeEvery(actions.DELETE_UPLOADED_DOCUMENT, deleteUploadedDocument),
        yield takeEvery(actions.DOWNLOAD_DOCUMENT, downloadUploadedDocument),
        yield takeEvery(actions.PREVIEW_DOCUMENT, previewUploadedDocument),      
    ]);
}