const actions = {

    SUBMIT_WELLNESS_CLAIM: 'SUBMIT_WELLNESS_CLAIM',
    submitWellnessClaim: (claim, obj, callback) => ({ type: actions.SUBMIT_WELLNESS_CLAIM, claim, obj, callback }),

    SAVE_WELLNESS_CLAIM: 'SAVE_WELLNESS_CLAIM',
    saveWellnessClaim: (claim, obj, callback) => ({ type: actions.SAVE_WELLNESS_CLAIM, claim, obj, callback  }),

    GET_SUBMITTED_WELLNESS_LIST: 'GET_SUBMITTED_WELLNESS_LIST',
    UPDATE_SUBMITTED_WELLNESS_LIST: 'UPDATE_SUBMITTED_WELLNESS_LIST',
    getSubmittedWellnessList: (claim) => ({ type: actions.GET_SUBMITTED_WELLNESS_LIST, claim }),

    GET_MY_SUBMITTED_WELLNESS_LIST: 'GET_MY_SUBMITTED_WELLNESS_LIST',
    UPDATE_MY_SUBMITTED_WELLNESS_LIST: 'UPDATE_MY_SUBMITTED_WELLNESS_LIST',
    getMySubmittedWellnessList: (claim) => ({ type: actions.GET_MY_SUBMITTED_WELLNESS_LIST, claim }),

    GET_WELLNESS_CLAIM_DETAIL: 'GET_WELLNESS_CLAIM_DETAIL',
    UPDATE_WELLNESS_CLAIM_DETAIL: 'UPDATE_WELLNESS_CLAIM_DETAIL',
    getWellnessClaimByID: (detail, obj, callback) => ({ type: actions.GET_WELLNESS_CLAIM_DETAIL, detail, obj, callback }),

    GET_WELLNESS_DRAFTED_CLAIM: 'GET_WELLNESS_DRAFTED_CLAIM',
    initWellnessDraftedData: () => ({ type: actions.GET_WELLNESS_DRAFTED_CLAIM }),

    DELETE_WELLNESS_DRAFTED_CLAIM: 'DELETE_WELLNESS_DRAFTED_CLAIM',
    deleteWellnessClaim: id => ({ type: actions.DELETE_WELLNESS_DRAFTED_CLAIM, id }),

    UPDATE_WELLNESS_CLAIM_HR:'UPDATE_WELLNESS_CLAIM_HR',
    updateWellnessClaim:(detail, obj, callback) => ({ type: actions.UPDATE_WELLNESS_CLAIM_HR, detail, obj, callback }),

    GET_WELLNESS_BALANCE: 'GET_WELLNESS_BALANCE',
    UPDATE_WELLNESS_BALANCE: 'UPDATE_WELLNESS_BALANCE',
    getWellnessBalance: () => ({ type: actions.GET_WELLNESS_BALANCE }),

    GET_WELLNESS_BALANCE_BY_ID: 'GET_WELLNESS_BALANCE_BY_ID',
    getWellnessBalanceByID: (detail) => ({ type: actions.GET_WELLNESS_BALANCE_BY_ID, detail }),

    GET_WELLNESS_BALANCE_BY_RECEIPT_DATE: 'GET_WELLNESS_BALANCE_BY_RECEIPT_DATE',
    UPDATE_WELLNESS_BALANCE_BY_RECEIPT_DATE: 'UPDATE_WELLNESS_BALANCE_BY_RECEIPT_DATE',
    getWellnessBalanceByReceiptDate: (eeID, year, obj, callback) => ({ type: actions.GET_WELLNESS_BALANCE_BY_RECEIPT_DATE, eeID, year, obj, callback  }),

    GET_CLAIM_ITEMS_REPORT: 'GET_CLAIM_ITEMS_REPORT',
    UPDATE_CLAIM_ITEMS_REPORT: 'UPDATE_CLAIM_ITEMS_REPORT',
    getWellnessClaimReport: (claim) => ({ type: actions.GET_CLAIM_ITEMS_REPORT, claim }),

    GET_SAP_BATCH: 'GET_SAP_BATCH',
    UPDATE_SAP_BATCH: 'UPDATE_SAP_BATCH',

    GET_SAP_BATCH_WITH_ID: 'GET_SAP_BATCH_WITH_ID',
    UPDATE_SAP_BATCH_WITH_ID: 'UPDATE_SAP_BATCH_WITH_ID',

    GET_SAP_BATCH_HISTORY: 'GET_SAP_BATCH_HISTORY',
    UPDATE_SAP_BATCH_HISTORY: 'UPDATE_SAP_BATCH_HISTORY',

    GET_SAP_BATCH_HISTORY_FILE: 'GET_SAP_BATCH_HISTORY_FILE',
    getSAPBatchReview: () => ({ type: actions.GET_SAP_BATCH }),
    getSAPBatchFile: () => ({ type: actions.GET_SAP_BATCH_WITH_ID }),
    getSAPBatchFileHistory: () => ({ type: actions.GET_SAP_BATCH_HISTORY }),
    getSAPBatchFileByID: id => ({ type: actions.GET_SAP_BATCH_HISTORY_FILE, id }),

    GET_WELLNESS_CHECK_RECEIPT_DATE: 'GET_WELLNESS_CHECK_RECEIPT_DATE',
    UPDATE_WELLNESS_CHECK_RECEIPT_DATE: 'UPDATE_WELLNESS_CHECK_RECEIPT_DATE',
    checkWellnessReceiptDate: (detail, obj, callback) => ({ type: actions.GET_WELLNESS_CHECK_RECEIPT_DATE, detail, obj, callback }),

};
export default actions;