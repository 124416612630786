import actions from './actions';

const initState = {
    loading: false,
    isError: false,
    documents: [],
    errorMsg:"",
};

export default function myclaimReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.UPDATE_UPLOADED_DOCUMENT: {
            return {
                ...state,
                documents: action.documents,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }     
        default:
            return state;
    }
}