import axios from 'axios'
import { takeWhile } from 'lodash';
import moment from 'moment';
import { call, all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import URL, { localStorageConfig } from '../../settings';
import actions from './actions';


export function* getClaim() {

    const localData = localStorage.getItem(localStorageConfig.curUserGUID);

    let newDate = new Date()
    let cMonth = newDate.getMonth() + 2;
    let cYear = newDate.getFullYear();
    const last6Month = moment().subtract(6, 'M');
    let sMonth = last6Month.month();
    let sYear = last6Month.year();
    if (sMonth == 0) {
        sMonth = 1;
    }
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetMyClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/0', config)

        yield put({
            type: actions.UPDATE_CLAIM,
            claims: data,
            loading: false,
            isError: false,
            errorMsg:"",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_CLAIM,
            statusOptions: [],
            claims: [],
            loading: false,
            isError: true,
            errorMsg:"No connection. Please contact IT.",
        });
    }
}
export function* updateclaimsaga({ claim }) {
    const localData = localStorage.getItem(localStorageConfig.curUserGUID);

    let newDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cMonth = newDate.month() + 2;
        cYear = newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2;
        cYear = newDate.getFullYear();
    }


    let last6Month;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(6, 'M');
    }
    let sMonth = last6Month.month()+1;
    let sYear = last6Month.year();
    let status = claim.statusSeleted == "" ? "0" : claim.statusSeleted
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetMyClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/' + status, config)
        yield put({
            type: actions.UPDATE_CLAIM,
            claims: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
            yield put({
                type: actions.UPDATE_CLAIM,
                claims: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
                //getClaimList()
            });
        }
}

export function* getCheckingOfficerClaimList(isSubmit) {

    yield put({
        type: actions.UPDATE_CHECKING_OFFICER_CLAIM_LIST,
        statusOptions: [],
        submittedClaimsOfficer: [],
        loading: true,
        isError: false,
        errorMsg: "",
    });
    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate = new Date()
    let cMonth = newDate.getMonth() + 2;
    let cYear = newDate.getFullYear();
    const last6Month = moment().subtract(6, 'M');
    let sMonth = last6Month.month();
    let sYear = last6Month.year();

    if (sMonth == 0) {
        sMonth = 1;
    }

    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetClaimOfficerClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + "/" + isSubmit.isSubmit + '/0',config)

        yield put({
            type: actions.UPDATE_CHECKING_OFFICER_CLAIM_LIST,
            submittedClaimsOfficer: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_CHECKING_OFFICER_CLAIM_LIST,
            submittedClaimsOfficer: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* updateCheckingOfficerClaimList({ claim }) {
    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cMonth = newDate.month() + 2;
        cYear = newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2;
        cYear = newDate.getFullYear();
    }


    let last6Month;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(6, 'M');
    }
    let sMonth = last6Month.month() + 1;
    let sYear = last6Month.year();
    let status = claim.statusSeleted == "" ? "0" : claim.statusSeleted
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetClaimOfficerClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + "/" + claim.isSubmit + '/' + status,config)
        yield put({
            type: actions.UPDATE_CHECKING_OFFICER_CLAIM_LIST,
            submittedClaimsOfficer: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_CHECKING_OFFICER_CLAIM_LIST,
            submittedClaimsOfficer: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
            //getClaimList()
        });
    }
}

export function* getApproverClaimList(isSubmit) {

    yield put({
        type: actions.UPDATE_APPROVER_CLAIM_LIST,
        statusOptions: [],
        submittedClaimsForApprover: [],
        loading: true,
        isError: false,
        errorMsg: "",
    });

    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate = new Date()
    let cMonth = newDate.getMonth() + 2;
    let cYear = newDate.getFullYear();
    const last6Month = moment().subtract(6, 'M');
    let sMonth = last6Month.month();
    let sYear = last6Month.year();
    if (sMonth == 0) {
        sMonth = 1;
    }
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetApproverClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + "/" + isSubmit.isSubmit + '/0',  config)

        yield put({
            type: actions.UPDATE_APPROVER_CLAIM_LIST,
            submittedClaimsForApprover: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_APPROVER_CLAIM_LIST,
            submittedClaimsForApprover: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* updateApproverClaimList({ claim }) {
    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cMonth = newDate.month() + 2;
        cYear = newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2;
        cYear = newDate.getFullYear();
    }


    let last6Month;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(6, 'M');
    }
    let sMonth = last6Month.month() + 1;
    let sYear = last6Month.year();
    let status = claim.statusSeleted == "" || claim.statusSeleted == undefined ? "0" : claim.statusSeleted
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetApproverClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + "/" + claim.isSubmit + '/' + status,config)
        yield put({
            type: actions.UPDATE_APPROVER_CLAIM_LIST,
            submittedClaimsForApprover: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_APPROVER_CLAIM_LIST,
            submittedClaimsForApprover: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
            //getClaimList()
        });
    }
}

export function* getSubmittedClaimForHR(isSubmit) {

    yield put({
        type: actions.UPDATE_SUBMITTED_CLAIM_FOR_HR,
        submittedClaimsForHR: [],
        statusOptions: [],
        loading: true,
        isError: false,
        errorMsg: "",
    });

    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate = new Date()
    let cMonth = newDate.getMonth() + 2;
    let cYear = newDate.getFullYear();
    const last6Month = moment().subtract(6, 'M');
    let sMonth = last6Month.month();
    let sYear = last6Month.year();
    if (sMonth == 0) {
        sMonth = 1;
    }
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetSubmittedHRList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + "/" + isSubmit.isSubmit + '/0',config)

        yield put({
            type: actions.UPDATE_SUBMITTED_CLAIM_FOR_HR,
            submittedClaimsForHR: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SUBMITTED_CLAIM_FOR_HR,
            submittedClaimsForHR: [],
            statusOptions:[],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* updatesubmittedclaimforhrsaga({ claim }) {
    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cMonth = newDate.month() + 2;
        cYear = newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2;
        cYear = newDate.getFullYear();
    }


    let last6Month;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(6, 'M');
    }
    let sMonth = last6Month.month() + 1;
    let sYear = last6Month.year();
    let status = claim.statusSeleted == "" || claim.statusSeleted == undefined ? "0" : claim.statusSeleted
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetSubmittedHRList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/' + claim.isSubmit + '/' + status,config)
        yield put({
            type: actions.UPDATE_SUBMITTED_CLAIM_FOR_HR,
            submittedClaimsForHR: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SUBMITTED_CLAIM_FOR_HR,
            submittedClaimsForHR: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
            //getClaimList()
        });
    }
}

export function* getTransportDraftedClaim() {

    yield put({
        type: actions.UPDATE_CLEAR_STATE
    });

    yield put({
        type: actions.UPDATE_DRAFTED_CLAIM,
        draftedClaims: [],
        loading: true,
        isError: false,
        errorMsg: "",
    });

    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate = new Date()
    let cMonth = newDate.getMonth() + 2;
    let cYear = newDate.getFullYear();
    const last6Month = moment().subtract(6, 'M');
    let sMonth = last6Month.month();
    let sYear = last6Month.year();
    if (sMonth == 0) {
        sMonth = 1;
    }
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetMyClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/1',config)

        yield put({
            type: actions.UPDATE_DRAFTED_CLAIM,
            draftedClaims: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DRAFTED_CLAIM,
            draftedClaims: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* updatedraftedclaimsaga({ claim }) {
    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cMonth = newDate.month() + 2;
        cYear = newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2;
        cYear = newDate.getFullYear();
    }


    let last6Month;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(6, 'M');
    }
    let sMonth = last6Month.month() + 1;
    let sYear = last6Month.year();
    let status = claim.statusSeleted == "" ? "0" : claim.statusSeleted
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetClaimOfficerClaimList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/' + status,config)
        yield put({
            type: actions.UPDATE_DRAFTED_CLAIM,
            draftedClaims: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DRAFTED_CLAIM,
            draftedClaims: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
            //getClaimList()
        });
    }
}

export function* getClaimByID({ id }) {
   
    if (id != undefined) {
        const token = localStorage.getItem(localStorageConfig.id_token);
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/' + id, config)
     
        yield put({
            type: actions.UPDATE_CLAIM_DETAIL,
            claim: data[0],
            loading: false,
            isError: false,
        });
    }
    else {
        yield put({
            type: actions.UPDATE_CLAIM_DETAIL,
            claim: [],
            loading: false,
            isError: false,
        });
    }
}

export function* submitClaim({ claim }) {
    yield put({
        type: actions.UPDATE_SUBMIT_CLAIM,
        claim: [],
        loading: true,
        isError: false,
    });
    if (claim != undefined) {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: claim

            };
            const token = localStorage.getItem(localStorageConfig.id_token);
            //const detail = yield call(fetch, URL.apiUrl + 'TransportHeader/', requestOptions)  
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'TransportHeader/', claim, config)

            //yield put({
            //    type: actions.UPDATE_SUBMIT_CLAIM,
            //    claim: data,
            //    loading: false,
            //    isError: true,
            //    errorMsg: data,
            //});
            if (data.id != undefined) {
                yield put({
                    type: actions.UPDATE_SUBMIT_CLAIM,
                    claim: data,
                    loading: false,
                    isError: false,
                });
            }
            else {
                if (data.includes("Duplicate claim")) {
                    yield put({
                        type: actions.UPDATE_SUBMIT_CLAIM,
                        claim: data,
                        loading: false,
                        isError: true,
                        errorMsg: data,
                    });
                }
            }
           
           
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_SUBMIT_CLAIM,
                isSubmitted: false,
                claim:[],
                loading: false,
                isError: true,
            });
        }
    }

}

export function* saveClaim({ claim }) {

    yield put({
        type: actions.UPDATE_SAVE_CLAIM,
        isSave: false,
        claim: [],
        loading: true,
        isError: false,
    });

    if (claim != undefined) {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: claim

            };
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'TransportHeader/SaveAsDraft', claim, config)
            yield put({
                type: actions.UPDATE_SAVE_CLAIM,
                isSave: true,
                claim: data,
                loading: false,
                isError: false,
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_SAVE_CLAIM,
                isSave: false,
                claim: [],
                loading: false,
                isError: true,
            });
        }
    }
}


export function* deleteTransportDraftedClaim({ id }) {
    if (id != undefined) {
        try {          
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.delete, URL.apiUrl + 'TransportHeader/' + id , config)
           
            yield put({
                type: actions.UPDATE_DRAFTED_CLAIM,
                draftedClaims: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_DRAFTED_CLAIM,             
                claim: [],
                loading: false,
                isError: true,
            });
        }
    }
}


export function* cancelClaim({ id }) {
    if (id != undefined) {
        try {
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.delete, URL.apiUrl + 'TransportHeader/CancelClaim/' + id + "/" + localStorage.getItem(localStorageConfig.curUserGUID), config)

            yield put({
                type: actions.UPDATE_CLAIM_DETAIL,
                claim: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_CLAIM_DETAIL,
                claim: [],
                loading: false,
                isError: true,
            });
        }
    }
}


export function* updateClaim({ claim }) {
    yield put({
        type: actions.UPDATE_CLAIM_ACTION_DETAIL,
        claim: [],
        loading: true,
        isError: false,
        errorMsg: "",
    });
    if (claim != undefined) {
        try {           
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'TransportHeader/UpdateClaim', claim ,config)
            yield put({
                type: actions.UPDATE_CLAIM_ACTION_DETAIL,   
                claim: data,
                loading: false,
                isError: false,
                errorMsg:"",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_CLAIM_ACTION_DETAIL,          
                claim: [],
                loading: false,
                isError: true,
                errorMsg:"Error.",
            });
        }
    }
}

export function* getClaimUploadedFile({ id }) {
    if (id != undefined) {
        try {
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetUploadedDocument/'+ id,config)
            yield put({
                type: actions.UPDATE_UPLOADED_DOCUMENT,
                documents: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_UPLOADED_DOCUMENT,
                documents:[],
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function* getClaimUploadedFileByDetail({ id }) {
    if (id != undefined) {
        try {
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetUploadedDocumentByDetail/' + id,config)
            yield put({
                type: actions.UPDATE_UPLOADED_DOCUMENT_BY_DETAIL,
                curDocuments: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_UPLOADED_DOCUMENT_BY_DETAIL,
                curDocuments: [],
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function* deleteUploadedDocument({ id, detailID, claimID, obj, callback }) {
    if (id != undefined) {
        try {
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.delete, URL.apiUrl + 'TransportHeader/DeleteDocument/' + id + "/" + localStorage.getItem(localStorageConfig.curUserGUID), config)

            yield put({
                type: actions.UPDATE_DELETE_UPLOADED_DOCUMENT_STATUS,
                deleteStatus: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });

            callback(detailID, claimID, obj);

            //const statusResult = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetUploadedDocumentByDetail/' + detailID, config)
            //yield put({
            //    type: actions.UPDATE_UPLOADED_DOCUMENT_BY_DETAIL,
            //    curDocuments: statusResult.data,
            //    loading: false,
            //    isError: false,
            //    errorMsg: "",
            //});

            //const statusResult1 = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetUploadedDocument/' + claimID, config)
            //yield put({
            //    type: actions.UPDATE_UPLOADED_DOCUMENT,
            //    documents: statusResult1.data,
            //    loading: false,
            //    isError: false,
            //    errorMsg: "",
            //});
            //getClaimUploadedFileByDetail(detailID);
            //getClaimUploadedFile(claimID);

        }
        catch (e) {
            yield put({
                type: actions.UPDATE_DELETE_UPLOADED_DOCUMENT_STATUS,
                deleteStatus:"",
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function* downloadDocument({ id }) {
    if (id != undefined) {
        try {

            axios({
                url: URL.apiUrl + 'TransportHeader/DownloadFile2/' + id.id, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', id.fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        }
        catch (e) {
            yield put({
                type: actions.UPLOAD_DOWNLOAD_DOCUMENT,
                downloadContent: {},
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function* previewDocument({ id }) {
    if (id != undefined) {
        try {

            axios({
                url: URL.apiUrl + 'TransportHeader/DownloadFile2/' + id, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('img');
                link.src = url;
                //document.body.appendChild(link);
            });
        }
        catch (e) {
            yield put({
                type: actions.UPLOAD_DOWNLOAD_DOCUMENT,
                downloadContent: {},
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function*  clearState() {
    yield put({
        type: actions.UPDATE_CLEAR_STATE        
    });
}

export function* getLateClaimDocument({ id }) {
    if (id != undefined) {
        try {
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetLateClaimDocument/' + id,config)
            yield put({
                type: actions.UPDATE_LATE_CLAIM_DOCUMENT,
                lateClaimdocuments: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_LATE_CLAIM_DOCUMENT,
                lateClaimdocuments: [],
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}



export function* deleteLateClaimDocument({ id }) {
    if (id != undefined) {
        try {
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.delete, URL.apiUrl + 'TransportHeader/DeleteLateClaimDocument/' + id + "/" + localStorage.getItem(localStorageConfig.curUserGUID),config)

            yield put({
                type: actions.UPDATE_DELETE_LATE_CLAIM_DOCUMENT_STATUS,
                lateClaimdocuments: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_DELETE_LATE_CLAIM_DOCUMENT_STATUS,
                lateClaimdocuments: [],
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function* downloadLateClaimDocument({ id }) {
    if (id != undefined) {
        try {

            axios({
                url: URL.apiUrl + 'TransportHeader/DownloadLateClaim/' + id.id, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', id.fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        }
        catch (e) {
            yield put({
                type: actions.UPLOAD_DOWNLOAD_DOCUMENT,
                downloadContent: {},
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function* previewLateClaimDocument({ id }) {
    if (id != undefined) {
        try {

            //const config = {
            //    headers: {
            //        'withCredentials': true,
            //        'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            //    }
            //};
            //const response = yield call(axios.get, URL.apiUrl + 'TransportHeader/DownloadLateClaim/' + id, config)
            //const data = yield call([response, response.json])
            ////const responseBody = yield response.data.blob();

            ////const response = yield call(fetch, 'http://www.haha.com/fetch_increment');
            ////const data = response.blob();
            //console.log(data); // prints "1"

            //const imgurl = "https://cdn62.picsart.com/182788826000202.jpg?type=webp&to=crop&r=256"
            //fetch(imgurl)
            //    .then(response => response.blob())
            //    .then(myBlob => {
            //        var urlCreator = window.URL || window.webkitURL;
            //        var imageUrl = urlCreator.createObjectURL(myBlob);
            //        const myImgElem = document.getElementById('my-img');
            //        myImgElem.src = imageUrl
            //    })

            //const body = yield call([data, 'blob']);

             //yield put({
             //           type: actions.UPDATE_PREVIEW_LATE_DOCUMENT,
             //    previewContent: window.URL.createObjectURL([data]),
             //           loading: false,
             //           isError: true,
             //           errorMsg: "Error.",
             //       });

                axios({
                    url: URL.apiUrl + 'TransportHeader/DownloadLateClaim/' + id, //your url
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));

                    const link = document.createElement('img');
                    link.src = url;
                   /* link.setAttribute('download', id.fileName); //or any other extension*/
                    document.body.appendChild(link);
                    /*link.click();*/

                    //if (url != undefined) {
                    //      yield put({
                    //    type: actions.UPDATE_PREVIEW_LATE_DOCUMENT,
                    //    previewContent: url,
                    //    loading: false,
                    //    isError: true,
                    //    errorMsg: "Error.",
                    //});
                    //}
                   getPreviewData(url);
                   // return 0;
                    //yield put({
                    //    type: actions.UPDATE_PREVIEW_LATE_DOCUMENT,
                    //    previewContent: url,
                    //    loading: false,
                    //    isError: true,
                    //    errorMsg: "Error.",
                    //});
                });

           
        }
        catch (e) {
            yield put({
                type: actions.UPLOAD_DOWNLOAD_DOCUMENT,
                downloadContent: {},
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}

export function* getPreviewData(url) {
      yield put({
                        type: actions.UPDATE_PREVIEW_LATE_DOCUMENT,
                        previewContent: url,
                        loading: false,
                        isError: true,
                        errorMsg: "Error.",
                    });
}

export function* getCheckingOfficerRequest({ id }) {
    if (id != undefined) {
        try {
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetCheckingOfficerRemark/' + id,config)
            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_REQUEST,
                checkingOfficerRequest: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_REQUEST,
                checkingOfficerRequest: [],
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}



export function* checkClaimItem({ claim }) {
    if (claim != undefined) {
        try {
            const token = localStorage.getItem(localStorageConfig.id_token);
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetClaimItemByDateTime/' + localStorage.getItem(localStorageConfig.curUserGUID) + '/' + claim.dateTimeOfCommencement + '/' + claim.id,config)
            yield put({
                type: actions.UPDATE_CHECK_CLAIM_ITEM,
                isExist: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_CHECK_CLAIM_ITEM,
                isExist: false,
                loading: false,
                isError: true,
                errorMsg: "Error.",
            });
        }
    }
}


export function* getSAPBatchReview() {
    try {
        const token = localStorage.getItem(localStorageConfig.id_token);
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GenerateBatchDetail', config)
        yield put({
            type: actions.UPDATE_SAP_BATCH,
            SAPBatchList: JSON.parse(data.ClaimList),
            TotalClaimAmount: data.TotalBatchAmount,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SAP_BATCH,
            SAPBatchList: [],
            loading: false,
            isError: true,
            errorMsg: "Error.",
        });
    }
}

export function* getSAPBatchFile() {
    try {
        const token = localStorage.getItem(localStorageConfig.id_token);
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GenerateBatchFile', config)

        if (data != null) {
            try {

                //axios({
                //    url: URL.apiUrl + 'TransportHeader/DownloadBatchFile/' + data, //your url
                //    method: 'GET',
                //    responseType: 'blob', // important
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', "Claims_" + data+ ".txt"); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
                //yield put({
                //    type: actions.UPDATE_SAP_BATCH,
                //    SAPBatchHistoryList: [],
                //    loading: false,
                //    isError: false,
                //    errorMsg: "",
                //});
            }
            catch (e) {
                yield put({
                    type: actions.UPDATE_SAP_BATCH_WITH_ID,
                    downloadContent: {},
                    loading: false,
                    isError: true,
                    errorMsg: "Error.",
                });
            }
        }

    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SAP_BATCH_WITH_ID,
            downloadContent: {},
            loading: false,
            isError: true,
            errorMsg: "Error.",
        });
    }
}

export function* getSAPBatchFileHistory() {
    try {
        const token = localStorage.getItem(localStorageConfig.id_token);
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetBatchFileHistory', config)
        yield put({
            type: actions.UPDATE_SAP_BATCH_HISTORY,
            SAPBatchHistoryList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SAP_BATCH_HISTORY,
            SAPBatchHistoryList: [],
            loading: false,
            isError: true,
            errorMsg: "Error.",
        });
    }
}

export function* getSAPBatchFileByID({ id }) {
    if (id != undefined) {
        //try {
        //    axios({
        //        url: URL.apiUrl + 'TransportHeader/DownloadBatchFile/' + id, //your url
        //        method: 'GET',
        //        responseType: 'blob', // important
        //    }).then((response) => {
        //        const url = window.URL.createObjectURL(new Blob([response.data]));
        //        const link = document.createElement('a');
        //        link.href = url;
        //        link.setAttribute('download', "Claims_" + id + ".txt"); //or any other extension
        //        document.body.appendChild(link);
        //        link.click();
        //    });            
        //}
        //catch (e) {
          
        //}
    }
}




export function* getNoPayLeaveDraftedClaim() {

    yield put({
        type: actions.UPDATE_CLEAR_STATE
    });

    yield put({
        type: actions.UPDATE_DRAFTED_CLAIM,
        draftedClaims: [],
        loading: true,
        isError: false,
        errorMsg: "",
    });

    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate = new Date()
    let cMonth = newDate.getMonth() + 2;
    let cYear = newDate.getFullYear();
    const last6Month = moment().subtract(6, 'M');
    let sMonth = last6Month.month();
    let sYear = last6Month.year();
    if (sMonth == 0) {
        sMonth = 1;
    }
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'NoPayLeave/GetMyList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/1', config)

        yield put({
            type: actions.UPDATE_DRAFTED_CLAIM,
            draftedClaims: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DRAFTED_CLAIM,
            draftedClaims: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}




export function* checkWellnessDuplicate(detail) {
    yield put({
        type: actions.UPDATE_WELLNESS_DUPLICATE,
        wellnessDuplicate: false,
        loading: true,
        isError: false,
    });

    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
   // const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/CheckReceiptDetail/' + detail.detail, config)
    const { data } = yield call(axios.post, URL.apiUrl + 'Wellness/CheckReceiptDetail', detail.detail, config)

    yield put({
        type: actions.UPDATE_WELLNESS_DUPLICATE,
        wellnessDuplicate: data,
        loading: false,
        isError: false,
    });

    detail.callback(data, detail.obj);
    
}

export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.GET_CLAIM_DETAIL, getClaimByID),
        yield takeEvery(actions.GET_CLAIM, getClaim),
        yield takeEvery(actions.UPDATE_CLAIM_SAGA, updateclaimsaga),

        yield takeEvery(actions.GET_CHECKING_OFFICER_CLAIM_LIST, getCheckingOfficerClaimList),
        yield takeEvery(actions.GET_CHECKING_OFFICER_CLAIM_LIST_SAGA, updateCheckingOfficerClaimList),

        yield takeEvery(actions.GET_APPROVER_CLAIM_LIST, getApproverClaimList),
        yield takeEvery(actions.UPDATE_SUBMITTED_CLAIM_FOR_APPROVER, updateApproverClaimList),

        yield takeEvery(actions.GET_SUBMITTED_CLAIM_FOR_HR, getSubmittedClaimForHR),
        yield takeEvery(actions.UPDATE_SUBMITTED_CLAIM_SAGA_FOR_HR, updatesubmittedclaimforhrsaga),
        yield takeEvery(actions.GET_TRANSPORT_DRAFTED_CLAIM, getTransportDraftedClaim),
        yield takeEvery(actions.UPDATE_DRAFTED_CLAIM_SAGA, updatedraftedclaimsaga),
        yield takeEvery(actions.SUBMIT_CLAIM, submitClaim),
        yield takeEvery(actions.SAVE_CLAIM, saveClaim),
        yield takeEvery(actions.DELETE_TRANSPORT_DRAFTED_CLAIM, deleteTransportDraftedClaim),
        yield takeEvery(actions.CANCEL_DRAFTED_CLAIM, cancelClaim),
        yield takeEvery(actions.UPDATE_CLAIM_ACTION, updateClaim),
        yield takeEvery(actions.GET_CLAIM_DETAIL_UPLOADED_DOCUMENT, getClaimUploadedFile),
        yield takeEvery(actions.GET_CLAIM_DETAIL_UPLOADED_DOCUMENT_BY_DETAIL, getClaimUploadedFileByDetail),
        yield takeEvery(actions.DELETE_UPLOADED_DOCUMENT, deleteUploadedDocument),
        yield takeEvery(actions.DOWNLOAD_DOCUMENT, downloadDocument),
        yield takeEvery(actions.CLEAR_STATE, clearState),        
        yield takeEvery(actions.GET_LATE_CLAIM_DOCUMENT, getLateClaimDocument),
        yield takeEvery(actions.DELETE_LATE_CLAIM_DOCUMENT, deleteLateClaimDocument),       
        yield takeEvery(actions.DOWNLOAD_LATE_CLAIM_DOCUMENT, downloadLateClaimDocument),        
        yield takeEvery(actions.PREVIEW_DOCUMENT, previewDocument),
        yield takeEvery(actions.PREVIEW_LATE_DOCUMENT, previewLateClaimDocument),
        yield takeEvery(actions.GET_CHECKING_OFFICER_REQUEST, getCheckingOfficerRequest),
        yield takeEvery(actions.CHECK_CLAIM_ITEM, checkClaimItem),
        yield takeEvery(actions.GET_SAP_BATCH, getSAPBatchReview),
        yield takeEvery(actions.GET_SAP_BATCH_WITH_ID, getSAPBatchFile),
        yield takeEvery(actions.GET_SAP_BATCH_HISTORY, getSAPBatchFileHistory),
        yield takeEvery(actions.GET_SAP_BATCH_HISTORY_FILE, getSAPBatchFileByID),
      
         yield takeEvery(actions.GET_NOPAYLEAVE_DRAFTED, getNoPayLeaveDraftedClaim),

    
        yield takeEvery(actions.GET_WELLNESS_DUPLICATE, checkWellnessDuplicate),

        
    ]);
}
