import getDevSidebar from '../../customApp/sidebar';
const options = [
  {
    key: 'home',
    label: 'sidebar.home',
    leftIcon: 'ion-android-home',
  },
    {
    key: 'newform',
    label: 'sidebar.createNewClaim',
    leftIcon: 'ion-android-add-circle',
	/*children: [
      {
        key: 'newclaim',
        label: 'sidebar.transportClaim',
      },
      {
        key: 'wellnessClaim',
        label: 'sidebar.wellnessClaim',
      }
    ],*/
    },
    {
        key: 'draftedClaim',
        label: 'sidebar.draftedClaimRecord',
        leftIcon: 'ion-ios-paper',
    },
  {
      key: 'submitted',
      label: 'sidebar.myRecord',
    leftIcon: 'ion-clipboard',
    },    
   /*{
       key: 'submittedClaimOfficer',
    label: 'sidebar.submittedForms',
    leftIcon: 'ion-ios-paper',
    },*/
    //{
    //    key: 'submittedPageApprover',
    //    label: 'sidebar.submittedFormsForApprover',
    //    leftIcon: 'ion-ios-paper',
    //},
    {
        key: 'submittedPageHR',
        label: 'sidebar.submittedPageHR',
        leftIcon: 'ion-ios-compose',
    },
    //{
    //    key: 'approvedHistory',
    //    label: 'sidebar.approvedHistory',
    //    leftIcon: 'ion-android-checkbox-outline',
    //},
    {
        key: 'processedHistory',
        label: 'sidebar.processedHistory',
        leftIcon: 'ion-android-checkbox-outline',
    },
    //{
    //    key: 'faq',
    //    label: 'sidebar.faq',
    //    leftIcon: 'ion-information-circled',
    //},
    /*{
        key: 'submittedClaimHR',
        label: 'sidebar.submittedClaimForHR',
        leftIcon: 'ion-ios-paper',
    },   
  {
      key: 'approvedClaimForCO',
    label: 'sidebar.approvedHistory',
    leftIcon: 'ion-android-checkbox-outline',
    },

    {
        key: 'approvedClaimForApprover',
        label: 'sidebar.approvedHistory',
        leftIcon: 'ion-android-checkbox-outline',
    },
    
    {
        key: 'approvedClaimForHR',
        label: 'sidebar.approvedHistory',
        leftIcon: 'ion-android-checkbox-outline',
    },*/

    {
        key: 'sapBatchFile',
        label: 'sidebar.batchFile',
        leftIcon: 'ion-ios-download',
        children: [
            {
                key: 'generateSAPBatchFile',
                label: 'sidebar.generateBatchFile',
            },
            {
                key: 'getBatchFileHistory',
                label: 'sidebar.getBatchFileHistory',
            },

        ],
    },
    
    {
        key: 'report',
        label: 'sidebar.report',
        leftIcon: 'ion-ios-list',
        children: [
            {
                key: 'reportForHR',
                label: 'sidebar.claimPending',
            },
            {
                key: 'report1',
                label: 'sidebar.report1',
            },
            {
                key:'reportSharedServices',
                label: 'sidebar.noPayLeave',
            },
            {
                key:'reportWellnessClaim',
                label: 'sidebar.wellnessClaimReport',
            }
            
           
        ],
    },
    {
        key: 'setting',
        label: 'sidebar.setting',
        leftIcon: 'ion-android-settings',
        children: [
            {
                key: 'adminSetting',
                label: 'sidebar.settingAdmin',
            },
            {
                key: 'approverSetting',
                label: 'sidebar.settingApprover',
            },
            {
                key: 'divisionHeadSetting',
                label: 'sidebar.settingDivisionHead',
            },
            {
                key: 'HRSetting',
                label: 'sidebar.settingHR',
            },
            {
                key: 'checkingApproverSetting',
                label: 'sidebar.settingCheckingApprover',
            },   
            {
                key: 'employeeSetting',
                label: 'sidebar.settingEmployee',
            },           
            //{
            //    key: 'signInWithPassword',
            //    label: 'sidebar.signInWithPassword',
            //},           
        ],
    },
   {
        key: 'userGuide',
       label: 'sidebar.userGuide',
       leftIcon: 'ion-information-circled',
    },
    //{
    //    key: 'checkingApproverSetting',
    //    label: 'sidebar.settingCheckingApprover',
    //    leftIcon: 'ion-android-person-add',
    //},
    //{
    //    key: 'approverSetting',
    //    label: 'sidebar.settingApprover',
    //    leftIcon: 'ion-android-person-add',
    //},
    //{
    //    key: 'HRSetting',
    //    label: 'sidebar.settingHR',
    //    leftIcon: 'ion-android-person-add',
    //},
    //{
    //    key: 'adminSetting',
    //    label: 'sidebar.settingAdmin',
    //    leftIcon: 'ion-android-person-add',
    //},
    //{
    //    key: 'signInWithPassword',
    //    label: 'sidebar.signInWithPassword',
    //    leftIcon: 'ion-android-person-add',
    //},
    
  //{
  //  key: 'mailbox',
  //  label: 'sidebar.email',
  //  leftIcon: 'ion-android-mail',
  //},
  //{
  //  key: 'chat',
  //  label: 'sidebar.chat',
  //  leftIcon: 'ion-chatbubbles',
  //},
  //{
  //  key: 'ecommerce',
  //  label: 'sidebar.ecommerce',
  //  leftIcon: 'ion-bag',
  //  children: [
  //    {
  //      key: 'shop',
  //      label: 'sidebar.shop',
  //    },
  //    {
  //      key: 'cart',
  //      label: 'sidebar.cart',
  //    },
  //    {
  //      key: 'checkout',
  //      label: 'sidebar.checkout',
  //    },
  //    {
  //      key: 'card',
  //      label: 'sidebar.cards',
  //    },
  //  ],
  //},
  //{
  //  key: 'maps',
  //  label: 'sidebar.maps',
  //  leftIcon: 'ion-map',
  //  children: [
  //    {
  //      key: 'googlemap',
  //      label: 'sidebar.googleMap',
  //    },
  //    {
  //      key: 'leafletmap',
  //      label: 'sidebar.leafletMap',
  //    },
  //  ],
  //},
  //{
  //  key: 'invoice',
  //  label: 'sidebar.invoice',
  //  leftIcon: 'ion-clipboard',
  //},
  //{
  //  key: 'youtubeSearch',
  //  label: 'sidebar.youtubeSearch',
  //  leftIcon: 'ion-social-youtube',
  //},
  //{
  //  key: 'calendar',
  //  label: 'sidebar.calendar',
  //  leftIcon: 'ion-calendar',
  //},
  //{
  //  key: 'notes',
  //  label: 'sidebar.notes',
  //  leftIcon: 'ion-ios-paper',
  //},
  //{
  //  key: 'todo',
  //  label: 'sidebar.todos',
  //  leftIcon: 'ion-android-checkbox-outline',
  //},
  //{
  //  key: 'firestorecrud',
  //  label: 'sidebar.firestorecrud',
  //  leftIcon: 'ion-fireball',

  //  children: [
  //    {
  //      key: 'articles',
  //      label: 'sidebar.firestorecrudarticle',
  //    },
  //    {
  //      key: 'investors',
  //      label: 'sidebar.firestorecrudinvestor',
  //    },
  //  ],
  //},
  //{
  //  key: 'contacts',
  //  label: 'sidebar.contacts',
  //  leftIcon: 'ion-android-person-add',
  //},
  //{
  //  key: 'shuffle',
  //  label: 'sidebar.shuffle',
  //  leftIcon: 'ion-grid',
  //},
  //{
  //  key: 'charts',
  //  label: 'sidebar.charts',
  //  leftIcon: 'ion-arrow-graph-up-right',
  //  children: [
  //    {
  //      key: 'googleChart',
  //      label: 'sidebar.googleCharts',
  //    },
  //    {
  //      key: 'reecharts',
  //      label: 'sidebar.recharts',
  //    },
  //    {
  //      key: 'reactChart2',
  //      label: 'sidebar.reactChart2',
  //    },
  //    {
  //      key: 'frappeChart',
  //      label: 'sidebar.frappeChart',
  //    },
  //  ],
  //},
  //{
  //  key: 'Forms',
  //  label: 'sidebar.forms',
  //  leftIcon: 'ion-android-mail',
  //  children: [
  //    {
  //      key: 'InputField',
  //      label: 'sidebar.input',
  //    },
  //    {
  //      key: 'editor',
  //      label: 'sidebar.editor',
  //    },
  //    {
  //      key: 'FormsWithValidation',
  //      label: 'sidebar.formsWithValidation',
  //    },
  //    {
  //      key: 'progress',
  //      label: 'sidebar.progress',
  //    },
  //    {
  //      key: 'button',
  //      label: 'sidebar.button',
  //    },
  //    {
  //      key: 'tab',
  //      label: 'sidebar.tab',
  //    },
  //    {
  //      key: 'checkbox',
  //      label: 'sidebar.checkbox',
  //    },
  //    {
  //      key: 'radiobox',
  //      label: 'sidebar.radiobox',
  //    },
  //    {
  //      key: 'selectbox',
  //      label: 'sidebar.selectbox',
  //    },
  //    {
  //      key: 'transfer',
  //      label: 'sidebar.transfer',
  //    },
  //    {
  //      key: 'autocomplete',
  //      label: 'sidebar.autocomplete',
  //    },
  //  ],
  //},
  //// {
  ////   key: 'gridLayout',
  ////   label: 'sidebar.boxOptions',
  ////   leftIcon: 'ion-cube'
  //// },
  //{
  //  key: 'uielements',
  //  label: 'sidebar.uiElements',
  //  leftIcon: 'ion-leaf',
  //  children: [
  //    {
  //      key: 'op_badge',
  //      label: 'sidebar.badge',
  //    },
  //    {
  //      key: 'op_card',
  //      label: 'sidebar.card2',
  //    },
  //    {
  //      key: 'op_carousel',
  //      label: 'sidebar.corusel',
  //    },
  //    {
  //      key: 'op_collapse',
  //      label: 'sidebar.collapse',
  //    },
  //    {
  //      key: 'op_popover',
  //      label: 'sidebar.popover',
  //    },
  //    {
  //      key: 'op_tooltip',
  //      label: 'sidebar.tooltip',
  //    },
  //    {
  //      key: 'op_tag',
  //      label: 'sidebar.tag',
  //    },
  //    {
  //      key: 'op_timeline',
  //      label: 'sidebar.timeline',
  //    },
  //    {
  //      key: 'dropdown',
  //      label: 'sidebar.dropdown',
  //    },
  //    {
  //      key: 'pagination',
  //      label: 'sidebar.pagination',
  //    },
  //    {
  //      key: 'rating',
  //      label: 'sidebar.rating',
  //    },
  //    {
  //      key: 'tree',
  //      label: 'sidebar.tree',
  //    },
  //  ],
  //},
  //{
  //  key: 'advancedUielements',
  //  label: 'sidebar.advancedElements',
  //  leftIcon: 'ion-flash',
  //  children: [
  //    {
  //      key: 'reactDates',
  //      label: 'sidebar.reactDates',
  //    },
  //    {
  //      key: 'codeMirror',
  //      label: 'sidebar.codeMirror',
  //    },
  //    {
  //      key: 'uppy',
  //      label: 'sidebar.uppy',
  //    },
  //    {
  //      key: 'dropzone',
  //      label: 'sidebar.dropzone',
  //    },
  //  ],
  //},
  //{
  //  key: 'feedback',
  //  label: 'sidebar.feedback',
  //  leftIcon: 'ion-thumbsup',
  //  children: [
  //    {
  //      key: 'alert',
  //      label: 'sidebar.alert',
  //    },
  //    {
  //      key: 'modal',
  //      label: 'sidebar.modal',
  //    },
  //    {
  //      key: 'message',
  //      label: 'sidebar.message',
  //    },
  //    {
  //      key: 'notification',
  //      label: 'sidebar.notification',
  //    },
  //    {
  //      key: 'popConfirm',
  //      label: 'sidebar.popConfirm',
  //    },
  //    {
  //      key: 'spin',
  //      label: 'sidebar.spin',
  //    },
  //  ],
  //},
  //{
  //  key: 'table',
  //  label: 'sidebar.tables',
  //  leftIcon: 'ion-android-menu',
  //  children: [
  //    {
  //      key: 'table_ant',
  //      label: 'sidebar.antTables',
  //    },
  //  ],
  //},
  //{
  //  key: 'pages',
  //  label: 'sidebar.pages',
  //  leftIcon: 'ion-document-text',
  //  children: [
  //    {
  //      key: '404',
  //      label: 'sidebar.404',
  //      withoutDashboard: true,
  //    },
  //    {
  //      key: '500',
  //      label: 'sidebar.500',
  //      withoutDashboard: true,
  //    },
  //    {
  //      key: 'signin',
  //      label: 'sidebar.signIn',
  //      withoutDashboard: true,
  //    },
  //    {
  //      key: 'signup',
  //      label: 'sidebar.signUp',
  //      withoutDashboard: true,
  //    },
  //    {
  //      key: 'forgotpassword',
  //      label: 'sidebar.forgotPw',
  //      withoutDashboard: true,
  //    },
  //    {
  //      key: 'resetpassword',
  //      label: 'sidebar.resetPw',
  //      withoutDashboard: true,
  //    },

  //    // {
  //    //   key: 'comingSoon',
  //    //   label: 'sidebar.comingSoon',
  //    //    withoutDashboard: true
  //    // }
  //  ],
  //},
  //{
  //  key: 'scrum-board',
  //  label: 'sidebar.scrumboard',
  //  leftIcon: 'ion-android-checkbox-outline',
  //},
  //...getDevSidebar,
];
export default options;
