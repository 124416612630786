import clone from 'clone';
import actions from './actions';

const initState = {
    claims: [],
    submittedClaimsOfficer: [],
    submittedClaimsForApprover: [],
    submittedClaimsForHR: [],
    draftedClaims: [],
    documents: [],
    curDocuments:[],
    currentClaim: [],
    currentClaimCO: {},
    currentClaimApprover: {},
    currentClaimHR: {},
    currentDraftedClaim: {}, 
    loading: true,
    isError: false,
    errorMsg: "",  
    deleteStatus: "",
    downloadContent: {},
    lateClaimdocuments: [],
    deleteLateClaimStatus: "",
    checkingOfficerRequest: [],
    isExist: false,
    countLateDocuments: "",
    countDocuments: "",
    SAPBatchList: [],
    SAPBatchHistoryList: [],
    previewContent: "",
    totalClaimAmount: 0,
    wellnessDuplicate:false,
};

export default function myclaimReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.UPDATE_CLAIM: {       
            return {
                ...state,
                claims: action.claims,           
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }
        case actions.UPDATE_CLAIM_DETAIL: {
            const a = action.claim;
            return {
                ...state,
                currentClaim: action.claim == undefined?[]:action.claim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,              
            };
        }
        case actions.UPDATE_SUBMIT_CLAIM: {
            return {
                ...state,              
                currentClaim: action.claim == undefined ? state.claims : action.claim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }   
        case actions.UPDATE_SAVE_CLAIM: {
            return {
                ...state,
                isSave: action.isSave,
                currentClaim: action.claim == undefined ? state.claims : action.claim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,              
            };
        }   
        case actions.UPDATE_CLAIM_ACTION_DETAIL: {
            return {
                ...state,
                currentClaim: action.claim == undefined ? state.claims : action.claim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,        
            };
        }   
        case actions.SELECT_CURRENT_CLAIM: {

            const claims = state.claims;
            const index = claims.map(claim => claim.id).indexOf(action.id);
            const currentClaim = claims[index];
            return {
                ...state,
                currentClaim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,             
            };
        }
        case actions.UPDATE_CHECKING_OFFICER_CLAIM_LIST: {          
            return {
                ...state,
                submittedClaimsOfficer: action.submittedClaimsOfficer,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,              
            };
        }
        case actions.SELECT_SUBMITTED_OFFICER_CURRENT_CLAIM: {

            const claims = state.submittedClaimsOfficer;
            const index = claims.map(claim => claim.id).indexOf(action.id);
            const currentClaim = claims.length>0 ? claims[index] : {};
            return {
                ...state,
                currentClaimCO: currentClaim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,              
            };
        }
        case actions.UPDATE_APPROVER_CLAIM_LIST: {
            return {
                ...state,
                submittedClaimsForApprover: action.submittedClaimsForApprover,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,         
            };
        }
        case actions.SELECT_SUBMITTED_CURRENT_CLAIM_FOR_APPROVER: {

            const claims = state.submittedClaimsForApprover;
            const index = claims.map(claim => claim.id).indexOf(action.id);
            const currentClaim = claims.length > 0 ? claims[index] : {};
            return {
                ...state,
                currentClaimApprover: currentClaim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,               
            };
        }
        case actions.UPDATE_SUBMITTED_CLAIM_FOR_HR: {         
            return {
                ...state,
                submittedClaimsForHR: action.submittedClaimsForHR,
 
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,              
            };
        }
        case actions.SELECT_SUBMITTED_CURRENT_CLAIM_FOR_HR: {

            const claims = state.submittedClaimsForHR;
            const index = claims.map(claim => claim.id).indexOf(action.id);
            const currentClaim = claims.length > 0 ? claims[index] : {};
            return {
                ...state,
                currentDraftedClaim: currentClaim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }
        case actions.UPDATE_DRAFTED_CLAIM: {
            return {
                ...state,
                draftedClaims: action.draftedClaims,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }
        case actions.UPDATE_DRAFTED_CLAIM_SAGA: {

            const claims = state.draftedClaims;
            const index = claims.map(claim => claim.id).indexOf(action.id);
            const currentClaim = claims.length > 0 ? claims[index] : {};
            return {
                ...state,
                currentDraftedClaim: currentClaim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }
        case actions.SELECT_CURRENT_DRAFTED_CLAIM: {

            const claims = state.draftedClaims;
            const index = claims.map(claim => claim.id).indexOf(action.id);
            const currentClaim = claims[index];
            return {
                ...state,
                currentClaim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }
        case actions.UPDATE_UPLOADED_DOCUMENT: {
            return {
                ...state,
                documents: action.documents,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }     

        case actions.UPDATE_UPLOADED_DOCUMENT_BY_DETAIL: {
            return {
                ...state,
                curDocuments: action.curDocuments,
                countDocuments: action.curDocuments != undefined ? action.curDocuments.length == 0 ? "" : action.curDocuments.length : "",
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }   
        case actions.UPDATE_DELETE_UPLOADED_DOCUMENT_STATUS: {
            return {
                ...state,
                deleteStatus: action.deleteStatus,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }
        case actions.UPLOAD_DOWNLOAD_DOCUMENT: {
            return {
                ...state,
                downloadContent: action.downloadContent,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }   
        case actions.UPDATE_CLEAR_STATE: {
            return {
                claims: [],
                submittedClaimsOfficer: [],
                submittedClaimsForApprover: [],
                submittedClaimsForHR: [],
                draftedClaims: [],
                documents: [],
                curDocuments: [],
                currentClaim: {},
                currentClaimCO: {},
                currentClaimApprover: {},
                currentClaimHR: {},
                currentDraftedClaim: {},
                loading: true,
                isError: false,
                errorMsg: "",
                deleteStatus: "",
                downloadContent: {},
            };
        }    
        case actions.UPDATE_LATE_CLAIM_DOCUMENT: {
            return {
                ...state,
                lateClaimdocuments: action.lateClaimdocuments,
                countLateDocuments: action.lateClaimdocuments != undefined ? action.lateClaimdocuments.length == 0 ? "" : action.lateClaimdocuments.length:"",
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }      
        case actions.UPDATE_DELETE_LATE_CLAIM_DOCUMENT_STATUS: {
            return {
                ...state,
                lateClaimdocuments: action.lateClaimdocuments,
                countLateDocuments: action.lateClaimdocuments != undefined ? action.lateClaimdocuments.length == 0 ? "" : action.lateClaimdocuments.length : "",
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }
        case actions.UPDATE_CHECKING_OFFICER_REQUEST: {
            return {
                ...state,
                checkingOfficerRequest: action.checkingOfficerRequest,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  
        case actions.UPDATE_CHECK_CLAIM_ITEM: {
            return {
                ...state,
                isExist: action.isExist,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  
        case actions.UPDATE_SAP_BATCH: {
            return {
                ...state,
                SAPBatchList: action.SAPBatchList,
                totalClaimAmount: action.TotalClaimAmount,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  

        case actions.UPDATE_SAP_BATCH_WITH_ID: {
            return {
                ...state,
                downloadContent: action.downloadContent,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }   

        case actions.UPDATE_SAP_BATCH_HISTORY: {
            return {
                ...state,
                SAPBatchHistoryList: action.SAPBatchHistoryList,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  
        case actions.UPDATE_PREVIEW_LATE_DOCUMENT: {
            return {
                ...state,
                previewContent: action.previewContent,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  
            
        case actions.TOGGLE_VIEW:
            return {
                ...state,
                enableEditView: action.view,
                editableInvoice: clone(state.currentClaim)
            };
        case actions.UPDATE_EDIT_CLAIM:
            return {
                ...state,
                editableInvoice: clone(action.invoice)
            };      
        case actions.UPDATE_WELLNESS_DUPLICATE:
            return {
                ...state,
                wellnessDuplicate: action.wellnessDuplicate,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        default:
            return state;
    }
}
