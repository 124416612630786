import clone from 'clone';
import actions from './actions';

const initState = {  
    loading: true,
    isError: false,
    errorMsg: "",
    claimType: "",
    checkingOfficerOptions: [],
    approverOptions: [],
    divisionHeadOption:[],
    hrOptions: "",
    countSubmittedClaim: [],
    countPendingCO: [],
    countPendingApprover: [],
    countPendingHR: [],  
    isCheckingOfficer: false,
    isApprover: false,
    isHR: false,
    isAdmin: false,
    isSuperAdmin: false,
    notification: [],
    claimTypeOption: [],
    transportTypeOptions: [],
    transportModeOptions: [],
    companiesOptions:[],
    divisionOptions: [],
    checkingOfficerList: [],
    approverList: [],
    divisionHeadList: [],
    HRRolesList:[],
    HRList: [],
    HeadOfHR:"",
    EmployeeList: [],
    AdminList: [],
    isAuthenticate: true,
    isLogin: false,
    wellnessOptions: [],
    employeeOptions: [],
    employeeName: '',
    statusOptions: [],
    isApproverDelegate: false,
    isDivisionHeadDelegate:false,
    isHRHead :false,
    isHRSharedService : false,
    isDivisionHead:false,
    isHRBP:false,
    HRCompany:[],
    HRDivision:[],
    HREmployeeId:[],
    HREmployeeName:[],
    HREmployeeId:[],
    HREmployeeName:[],
    EmployeeListByEEID:[],
    EmployeeListByEEName:[],
    EmployeeSettingList:[],
};

export default function masterReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.UPDATE_TYPE_BY_TYPE_NAME: {         
            return {
                ...state,
                claimType: action.claimType,                
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }     
        case actions.UPDATE_STATUS_OPTIONS: {
            return {
                ...state,
                statusOptions: action.statusOptions,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }      
        case actions.UPDATE_MASTER_OPTIONS: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                checkingOfficerOptions: action.checkingOfficerOptions,
                approverOptions: action.approverOptions,
                hrOptions: action.hrOptions,
            };
        }      
        case actions.UPDATE_DASHBOARD_DETAIL: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                countSubmittedClaim: action.countSubmittedClaim,
                countPendingCO: action.countPendingCO,
                countPendingApprover: action.countPendingApprover,
                countPendingHR: action.countPendingHR,
            };
        }     
        case actions.UPDATE_EMPLOYEE_ROLE: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                isCheckingOfficer: action.isCheckingOfficer,
                isApprover: action.isApprover,
                isHR: action.isHR,
                isAdmin: action.isAdmin,
                isSuperAdmin: action.isSuperAdmin,
                isHRHead : action.isHRHead,
                isHRBP: action.isHRBP,
                isHRSharedService : action.isHRSharedService,
                isDivisionHead : action.isDivisionHead,
            };
        }
        case actions.UPDATE_NOTIFICATION: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                notification: action.notification,                
            };
        }
        case actions.UPDATE_CLAIM_TYPE: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                claimTypeOption: action.claimTypeOption,
            };
        }
        case actions.UPDATE_TRANSPORT_OPTIONS: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                transportTypeOptions: action.transportTypeOptions,
            };
        }
        case actions.UPDATE_PUBLIC_TRANSPORT_OPTIONS: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                transportModeOptions: action.transportModeOptions,
            };
        }
        case actions.UPDATE_COMPANIES: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                companiesOptions: action.companiesOptions,
                isAuthenticate: action.isAuthenticate,
            };
        }
        case actions.UPDATE_DIVISIONS: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                divisionOptions: action.divisionOptions,
            };
        }
        case actions.UPDATE_CHECKING_OFFICER_LIST: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                checkingOfficerList: action.checkingOfficerList,
            };
        }
        case actions.UPDATE_APPROVER_LIST: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                approverList: action.approverList,
            };
        }
        case actions.UPDATE_DIVISION_HEAD_LIST: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                divisionHeadList: action.divisionHeadList,
            };
        }        
        case actions.UPDATE_HR_ROLE_TYPE: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                HRRolesList: action.HRRolesList,
            };
        }
        case actions.UPDATE_HR_COMPANY: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                HRCompany: action.HRCompany,
            };
        }
        case actions.UPDATE_HR_DIVISION: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                HRDivision: action.HRDivision,
            };
        }
        case actions.UPDATE_HR_EMPLOYEEID: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                HREmployeeId: action.HREmployeeId,
            };
        }
        case actions.UPDATE_HR_EMPLOYEENAME: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                HREmployeeName: action.HREmployeeName,
            };
        }
        case actions.UPDATE_HR_LIST: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                HRList: action.HRList,
            };
        }
        case actions.UPDATE_HEAD_OF_HR: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                HeadOfHR: action.HeadOfHR,
            };
        }
        case actions.UPDATE_EMPLOYEE_LIST: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                EmployeeList: action.EmployeeList,
                EmployeeListByEEID: action.EmployeeListByEEID,
                EmployeeListByEEName: action.EmployeeListByEEName,
            };
        }
        case actions.UPDATE_EMPLOYEE_LIST_BY_EEID: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                EmployeeListByEEID: action.EmployeeListByEEID,
            };
        }
        case actions.UPDATE_EMPLOYEE_BY_EENAME: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                EmployeeListByEEName: action.EmployeeListByEEName,
            };
        }
        case actions.UPDATE_EMPLOYEE_SETTING_LIST: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                EmployeeSettingList: action.EmployeeSettingList,
            };
        }
        case actions.UPDATE_ADMIN_LIST: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                AdminList: action.AdminList,
            };
        }    
        case actions.UPDATE_SIGN_IN_ONBEHALF: {
            return {
                ...state,
                isLogin: action.isLogin,               
            };
        }  
        case actions.UPDATE_WELLNESS_OPTIONS: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                wellnessOptions: action.wellnessOptions,
            };
        }
        case actions.UPDATE_NO_PAY_LEAVE_MASTER_OPTIONS: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                divisionHeadOption: action.divisionHeadOption,
                approverOptions: action.approverOptions,
                employeeName: action.employeeName
            };
        }     
        case actions.UPDATE_ONBEHALF_EMPLOYEE: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                employeeOptions: action.employeeOptions,
            };
        }     
        case actions.UPDATE_APPROVER_DELEGATE: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                isApproverDelegate: action.isApproverDelegate,
            };
        }  
        case actions.UPDATE_DIVISION_HEAD_DELEGATE: {
            return {
                ...state,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
                isDivisionHeadDelegate: action.isDivisionHeadDelegate,
            };
        }  
        default:
            return state;
    }
}
