import actions from "./actions";

const initState = {
    idToken: null,
    employeeDetail: [],
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {               
                idToken: action.token,
                employeeDetail: action.employeeDetail,
            };
        case actions.UPDATE_EMPLOYEE_DETAIL:
            return {
                employeeDetail: action.employeeDetail,
            };
        case actions.LOGOUT:
            return initState;      
        default:
            return state;
    }
}
