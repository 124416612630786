import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "./options";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import masterActions from '../../redux/master/actions';
import Logo from "../../components/utility/logo";
import { Options } from "smooth-scrollbar/options";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;
const { initEmployeeRole} = masterActions;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
    componentDidMount() {
        const { initEmployeeRole} = this.props;      
        initEmployeeRole(this, function (role, obj) {
           
        });
      
    }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };
    getMenuItem = ({ singleOption, submenuStyle, submenuColor, isCheckingOfficer, isApprover, isHR, isAdmin, isSuperAdmin,
        isHRHead,isHRSharedService,isDivisionHead}) => {
    const { key, label, leftIcon, children } = singleOption;
        const url = stripTrailingSlash(this.props.url);


        if (children) {
            if (key == "sapBatchFile") {
                if (isHR || isHRSharedService ||isSuperAdmin ) {
                    return (

                        <SubMenu
                            key={key}
                            title={
                                <span className="isoMenuHolder" style={submenuColor}>
                                    <i className={leftIcon} />
                                    <span className="nav-text">
                                        <IntlMessages id={label} />
                                    </span>
                                </span>
                            }
                        >
                            {children.map(child => {
                                const linkTo = child.withoutDashboard
                                    ? `/${child.key}`
                                    : `${url}/${child.key}`;
                                return (
                                    <Menu.Item style={submenuStyle} key={child.key}>
                                        <Link style={submenuColor} to={linkTo}>
                                            <IntlMessages id={child.label} />
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                        </SubMenu>
                    );
                }
            }

            else if (key == "setting") {
                if (isHRSharedService || isSuperAdmin || isAdmin) {
                    return (
                        <SubMenu
                            key={key}
                            title={
                                <span className="isoMenuHolder" style={submenuColor}>
                                    <i className={leftIcon} />
                                    <span className="nav-text">
                                        <IntlMessages id={label} />
                                    </span>
                                </span>
                            }
                        >
                            {children.map(child => {
                                const linkTo = child.withoutDashboard
                                    ? `/${child.key}`
                                    : `${url}/${child.key}`;

                                if (isSuperAdmin) {
                                    {/*if (child.key == "adminSetting" || child.key == "HRSetting" || child.key == "signInWithPassword" || child.key == "approverSetting" || child.key == "divisionHeadSetting" || child.key == "checkingApproverSetting" || child.key == "employeeSetting") {*/ }
                                    if (child.key == "adminSetting" || child.key == "HRSetting" || child.key == "signInWithPassword" || child.key == "employeeSetting") {

                                        return (
                                            <Menu.Item style={submenuStyle} key={child.key}>
                                                <Link style={submenuColor} to={linkTo}>
                                                    <IntlMessages id={child.label} />
                                                </Link>
                                            </Menu.Item>
                                        );
                                    }
                                }
                                else if (isAdmin) {
                                    {/*if (child.key == "approverSetting" || child.key == "divisionHeadSetting" || child.key == "checkingApproverSetting" || child.key == "signInWithPassword") {*/ }
                                    if (child.key == "signInWithPassword") {
                                        return (
                                            <Menu.Item style={submenuStyle} key={child.key}>
                                                <Link style={submenuColor} to={linkTo}>
                                                    <IntlMessages id={child.label} />
                                                </Link>
                                            </Menu.Item>
                                        );
                                    }

                                }
                                else if (isHRSharedService) {
                                    if (child.key == "employeeSetting") {
                                        return (
                                            <Menu.Item style={submenuStyle} key={child.key}>
                                                <Link style={submenuColor} to={linkTo}>
                                                    <IntlMessages id={child.label} />
                                                </Link>
                                            </Menu.Item>
                                        );
                                    }

                                }

                                if (child.key == "signInWithPassword") {
                                    return (
                                        <Menu.Item style={submenuStyle} key={child.key}>
                                            <Link style={submenuColor} to={linkTo}>
                                                <IntlMessages id={child.label} />
                                            </Link>
                                        </Menu.Item>
                                    );
                                }



                            })}
                        </SubMenu>
                    );
                }
            }
            else if (key == "report") {
                if (isHRSharedService || isSuperAdmin) {
                    return (

                        <SubMenu
                            key={key}
                            title={
                                <span className="isoMenuHolder" style={submenuColor}>
                                    <i className={leftIcon} />
                                    <span className="nav-text">
                                        <IntlMessages id={label} />
                                    </span>
                                </span>
                            }
                        >
                            {children.map(child => {
                                const linkTo = child.withoutDashboard
                                    ? `/${child.key}`
                                    : `${url}/${child.key}`;
                                    if(child.key =="reportWellnessClaim"){
                                        if (isHRSharedService || isSuperAdmin){
                                return (
                                    <Menu.Item style={submenuStyle} key={child.key}>
                                        <Link style={submenuColor} to={linkTo}>
                                            <IntlMessages id={child.label} />
                                        </Link>
                                    </Menu.Item>
                                );
                                        }
                                    }
                            })}
                        </SubMenu>
                    );
                }
            }

        }
      /*  alert(key);*/
        if (key == "submittedClaimOfficer" || key =="approvedClaimForCO") {
            if (isCheckingOfficer) {
                return (

                    <Menu.Item key={key}>
                        <Link to={`${url}/${key}`}>
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon} />
                                <span className="nav-text">
                                    <IntlMessages id={label} />
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                );
            }
        }
        else if (key == "submittedClaimApprover" || key == "approvedHistory" || key == "submittedPageApprover") {
            if (isApprover ||isHRHead ||isDivisionHead) {
                return (

                    <Menu.Item key={key}>
                        <Link to={`${url}/${key}`}>
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon} />
                                <span className="nav-text">
                                    <IntlMessages id={label} />
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                );
            }
        }
        else if (key == "processedHistory" || key == "generateSAPBatchFile" || key == "getBatchFileHistory" || key == "sapBatchFile" || key == "submittedPageHR") {
            if (isHR) {
                return (

                    <Menu.Item key={key}>
                        <Link to={`${url}/${key}`}>
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon} />
                                <span className="nav-text">
                                    <IntlMessages id={label} />
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                );
            }
        }
        else if (key == "setting" ) {
            if (isHRSharedService || isSuperAdmin || isAdmin || isHR) {
                return (

                    <Menu.Item key={key}>
                        <Link to={`${url}/${key}`}>
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon} />
                                <span className="nav-text">
                                    <IntlMessages id={label} />
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                );
            }
        }
        else if (key == "report" ) {
            if (isHRSharedService) {
                return (

                    <Menu.Item key={key}>
                        <Link to={`${url}/${key}`}>
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon} />
                                <span className="nav-text">
                                    <IntlMessages id={label} />
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                );
            }
        }
        else if (key == "userGuide") {        
                return (

                    <Menu.Item key={key}>
                        <Link to={``} onClick={() => window.open("https://ehrform.cpgcorp.com.sg/User%20Manual%20(Wellness%20Claim).pdf", '_blank', 'noreferrer')}>
                            <span className="isoMenuHolder" style={submenuColor}>
                                <i className={leftIcon} />
                                <span className="nav-text">
                                    <IntlMessages id={label} />
                                </span>
                            </span>
                        </Link>
                    </Menu.Item>
                );
          
        }
       
        else {
            return (

                <Menu.Item key={key}>
                    <Link to={`${url}/${key}`}>
                        <span className="isoMenuHolder" style={submenuColor}>
                            <i className={leftIcon} />
                            <span className="nav-text">
                                <IntlMessages id={label} />
                            </span>
                        </span>
                    </Link>
                </Menu.Item>
            );
        }
  };
   
  render() {
      const { app, toggleOpenDrawer, customizedTheme, height, master } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = event => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };
      const isCheckingOfficer = master.isCheckingOfficer;
      const isApprover = master.isApprover;
      const isHR = master.isHR;
      const isAdmin = master.isAdmin;
      const isSuperAdmin = master.isSuperAdmin;
      const isHRHead = master.isHRHead;
      const isHRSharedService = master.isHRSharedService;
      const isDivisionHead = master.isDivisionHead;
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map(singleOption =>
                  this.getMenuItem({ submenuStyle, submenuColor, singleOption, isCheckingOfficer, isApprover, isHR, isAdmin, isSuperAdmin,
                    isHRHead,isHRSharedService,isDivisionHead})
              )}
              {/* Demo Menu */}
              {/*<SubMenu*/}
              {/*  key="sub1"*/}
              {/*  title={*/}
              {/*    <span className="isoMenuHolder" style={submenuColor}>*/}
              {/*      <i className="ion-android-options" />*/}
              {/*      <span className="nav-text">*/}
              {/*        <IntlMessages id="sidebar.menuLevels" />*/}
              {/*      </span>*/}
              {/*    </span>*/}
              {/*  }*/}
              {/*>*/}
              {/*  <MenuItemGroup*/}
              {/*    key="g1"*/}
              {/*    title={<IntlMessages id="sidebar.item1" />}*/}
              {/*  >*/}
              {/*    <Menu.Item style={submenuStyle} key="1">*/}
              {/*      <IntlMessages id="sidebar.option1" />*/}
              {/*    </Menu.Item>*/}
              {/*    <Menu.Item style={submenuStyle} key="2">*/}
              {/*      <IntlMessages id="sidebar.option2" />*/}
              {/*    </Menu.Item>*/}
              {/*  </MenuItemGroup>*/}
              {/*  <MenuItemGroup*/}
              {/*    key="g2"*/}
              {/*    title={<IntlMessages id="sidebar.item2" />}*/}
              {/*  >*/}
              {/*    <Menu.Item style={submenuStyle} key="3">*/}
              {/*      <IntlMessages id="sidebar.option3" />*/}
              {/*    </Menu.Item>*/}
              {/*    <Menu.Item style={submenuStyle} key="4">*/}
              {/*      <IntlMessages id="sidebar.option4" />*/}
              {/*    </Menu.Item>*/}
              {/*  </MenuItemGroup>*/}
              {/*</SubMenu>*/}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
        height: state.App.height,
        master: state.Master,
  }),
    { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed, initEmployeeRole }
)(Sidebar);
