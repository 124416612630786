const actions = {

    GET_CLAIM: 'GET_CLAIM',
    GET_CLAIM_DETAIL:'GET_CLAIM_DETAIL',
    GET_SUBMITTED_CLAIM: 'GET_SUBMITTED_CLAIM',
    
    GET_SUBMITTED_CLAIM_FOR_HR: 'GET_SUBMITTED_CLAIM_FOR_HR',

    GET_TRANSPORT_DRAFTED_CLAIM: 'GET_TRANSPORT_DRAFTED_CLAIM',
    UPDATE_DRAFTED_CLAIM: 'UPDATE_DRAFTED_CLAIM',
    DELETE_TRANSPORT_DRAFTED_CLAIM:'DELETE_TRANSPORT_DRAFTED_CLAIM',
    UPDATE_DRAFTED_CLAIM_SAGA: 'UPDATE_DRAFTED_CLAIM_SAGA',

    SUBMIT_CLAIM:'SUBMIT_CLAIM',
    UPDATE_SUBMIT_CLAIM: 'UPDATE_SUBMIT_CLAIM',

    SAVE_CLAIM: 'SAVE_CLAIM',
    UPDATE_SAVE_CLAIM: 'UPDATE_SAVE_CLAIM',

    UPDATE_CLAIM_ACTION: 'UPDATE_CLAIM_ACTION',
    UPDATE_CLAIM_ACTION_DETAIL: 'UPDATE_CLAIM_ACTION_DETAIL',

    CANCEL_DRAFTED_CLAIM: 'CANCEL_DRAFTED_CLAIM',

    UPDATE_CLAIM: 'UPDATE_CLAIM',
    UPDATE_CLAIM_DETAIL: 'UPDATE_CLAIM_DETAIL',

    CLEAR_STATE: 'CLEAR_STATE',
    UPDATE_CLEAR_STATE: 'UPDATE_CLEAR_STATE',

    CHECK_CLAIM_ITEM: 'CHECK_CLAIM_ITEM',
    UPDATE_CHECK_CLAIM_ITEM:'UPDATE_CHECK_CLAIM_ITEM',

    GET_CLAIM_DETAIL_UPLOADED_DOCUMENT: 'GET_CLAIM_DETAIL_UPLOADED_DOCUMENT',
    GET_CLAIM_DETAIL_UPLOADED_DOCUMENT_BY_DETAIL: 'GET_CLAIM_DETAIL_UPLOADED_DOCUMENT_BY_DETAIL',
    UPDATE_UPLOADED_DOCUMENT: 'UPDATE_UPLOADED_DOCUMENT',
    UPDATE_UPLOADED_DOCUMENT_BY_DETAIL: 'UPDATE_UPLOADED_DOCUMENT_BY_DETAIL',
    DELETE_UPLOADED_DOCUMENT: 'DELETE_UPLOADED_DOCUMENT',
    UPDATE_DELETE_UPLOADED_DOCUMENT_STATUS: 'UPDATE_DELETE_UPLOADED_DOCUMENT_STATUS',
    DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
    UPLOAD_DOWNLOAD_DOCUMENT: 'UPLOAD_DOWNLOAD_DOCUMENT',
    PREVIEW_DOCUMENT: 'PREVIEW_DOCUMENT',
    PREVIEW_LATE_DOCUMENT: 'PREVIEW_LATE_DOCUMENT',
    UPDATE_PREVIEW_LATE_DOCUMENT: 'UPDATE_PREVIEW_LATE_DOCUMENT',

    GET_LATE_CLAIM_DOCUMENT: 'GET_LATE_CLAIM_DOCUMENT',
    UPDATE_LATE_CLAIM_DOCUMENT: 'UPDATE_LATE_CLAIM_DOCUMENT',
    DELETE_LATE_CLAIM_DOCUMENT: 'DELETE_LATE_CLAIM_DOCUMENT',
    UPDATE_DELETE_LATE_CLAIM_DOCUMENT_STATUS: 'UPDATE_DELETE_LATE_CLAIM_DOCUMENT_STATUS',
    DOWNLOAD_LATE_CLAIM_DOCUMENT:'DOWNLOAD_LATE_CLAIM_DOCUMENT',

    UPDATE_CLAIM_SAGA: 'UPDATE_CLAIM_SAGA',
    SELECT_CURRENT_CLAIM: 'SELECT_CURRENT_CLAIM',
    SELECT_CURRENT_DRAFTED_CLAIM: 'SELECT_CURRENT_DRAFTED_CLAIM',

    GET_CHECKING_OFFICER_CLAIM_LIST: 'GET_CHECKING_OFFICER_CLAIM_LIST',
    GET_CHECKING_OFFICER_CLAIM_LIST_SAGA: 'GET_CHECKING_OFFICER_CLAIM_LIST_SAGA',
    UPDATE_CHECKING_OFFICER_CLAIM_LIST: 'UPDATE_CHECKING_OFFICER_CLAIM_LIST',

    GET_CHECKING_OFFICER_REQUEST: 'GET_CHECKING_OFFICER_REQUEST',
    UPDATE_CHECKING_OFFICER_REQUEST: 'UPDATE_CHECKING_OFFICER_REQUEST',

    GET_APPROVER_CLAIM_LIST: 'GET_APPROVER_CLAIM_LIST',
    UPDATE_SUBMITTED_CLAIM_FOR_APPROVER: 'UPDATE_SUBMITTED_CLAIM_FOR_APPROVER',
    UPDATE_APPROVER_CLAIM_LIST: 'UPDATE_APPROVER_CLAIM_LIST',

    UPDATE_SUBMITTED_CLAIM_FOR_HR: 'UPDATE_SUBMITTED_CLAIM_FOR_HR',
    UPDATE_SUBMITTED_CLAIM_SAGA_FOR_HR: 'UPDATE_SUBMITTED_CLAIM_SAGA_FOR_HR',

    GET_ACTIONLOG: 'GET_ACTIONLOG',
    UPDATE_ACTIONLOG: 'UPDATE_ACTIONLOG',

    GET_SAP_BATCH: 'GET_SAP_BATCH',
    UPDATE_SAP_BATCH: 'UPDATE_SAP_BATCH',

    GET_SAP_BATCH_WITH_ID: 'GET_SAP_BATCH_WITH_ID',
    UPDATE_SAP_BATCH_WITH_ID: 'UPDATE_SAP_BATCH_WITH_ID',

    GET_SAP_BATCH_HISTORY: 'GET_SAP_BATCH_HISTORY',
    UPDATE_SAP_BATCH_HISTORY: 'UPDATE_SAP_BATCH_HISTORY',

    GET_SAP_BATCH_HISTORY_FILE: 'GET_SAP_BATCH_HISTORY_FILE',
   
    SELECT_SUBMITTED_OFFICER_CURRENT_CLAIM: 'SELECT_SUBMITTED_OFFICER_CURRENT_CLAIM',
    SELECT_SUBMITTED_CURRENT_CLAIM_FOR_APPROVER: 'SELECT_SUBMITTED_CURRENT_CLAIM_FOR_APPROVER',
    SELECT_SUBMITTED_CURRENT_CLAIM_FOR_HR: 'SELECT_SUBMITTED_CURRENT_CLAIM_FOR_HR',
    SELECT_DRAFTED_CURRENT_CLAIM: 'SELECT_DRAFTED_CURRENT_CLAIM',
    TOGGLE_VIEW: 'CLAIM_TOGGLE_VIEW',
    UPDATE_EDIT_CLAIM: 'CLAIM_UPDATE_EDIT_CLAIM',

    getMyClaimList: () => ({ type: actions.GET_CLAIM }),

    //for checking officer
    initSubmittedData: isSubmit => ({ type: actions.GET_CHECKING_OFFICER_CLAIM_LIST, isSubmit }),
    updateCheckingOfficerClaimList: claim => ({ type: actions.GET_CHECKING_OFFICER_CLAIM_LIST_SAGA, claim }),

    initSubmittedDataForApprover: isSubmit => ({ type: actions.GET_APPROVER_CLAIM_LIST, isSubmit }), 
    updateSubmittedClaimForApprover: claim => ({ type: actions.UPDATE_SUBMITTED_CLAIM_FOR_APPROVER, claim }),

    initSubmittedDataForHR: isSubmit => ({ type: actions.GET_SUBMITTED_CLAIM_FOR_HR, isSubmit }),
    initTransportDraftedData: () => ({ type: actions.GET_TRANSPORT_DRAFTED_CLAIM }),

    deleteTransportClaim: id => ({ type: actions.DELETE_TRANSPORT_DRAFTED_CLAIM , id}),

    cancelClaim: id => ({ type: actions.CANCEL_DRAFTED_CLAIM, id }),

    updateClaim: claim => ({ type: actions.UPDATE_CLAIM_SAGA, claim }),
   
  
    updateSubmittedClaimForHR: claim => ({ type: actions.UPDATE_SUBMITTED_CLAIM_SAGA_FOR_HR, claim }),
    updateDraftedClaim: claim => ({ type: actions.UPDATE_DRAFTED_CLAIM_SAGA, claim }),

    submitClaim: claim => ({ type: actions.SUBMIT_CLAIM, claim }),
    saveClaim: claim => ({ type: actions.SAVE_CLAIM, claim }),

    updateClaimAction: claim=> ({ type: actions.UPDATE_CLAIM_ACTION, claim }),

    getClaimDetail: id => ({ type: actions.GET_CLAIM_DETAIL, id }),
    getClaimDetailUploadedDocument: id => ({ type: actions.GET_CLAIM_DETAIL_UPLOADED_DOCUMENT, id }),
    getClaimDetailUploadedDocumentByDetail: id => ({ type: actions.GET_CLAIM_DETAIL_UPLOADED_DOCUMENT_BY_DETAIL, id }),
    deleteUploadedDocument: (id, detailID, claimID, obj, callback) => ({ type: actions.DELETE_UPLOADED_DOCUMENT, id, detailID, claimID, obj, callback }),
    downloadUploadedDocument: id => ({ type: actions.DOWNLOAD_DOCUMENT, id }),
    previewUploadedDocument: id => ({ type: actions.PREVIEW_DOCUMENT, id }),
    previewUploadedLateDocument: id => ({ type: actions.PREVIEW_LATE_DOCUMENT, id }),

    getLateClaimDocument: id => ({ type: actions.GET_LATE_CLAIM_DOCUMENT, id }),
    deleteLateClaimDocument: id => ({ type: actions.DELETE_LATE_CLAIM_DOCUMENT, id }),
    downloadLateClaimDocument: id => ({ type: actions.DOWNLOAD_LATE_CLAIM_DOCUMENT, id }),

    getActionLog: id => ({ type: actions.GET_ACTIONLOG, id }),

    getCheckingOfficerRequest: id => ({ type: actions.GET_CHECKING_OFFICER_REQUEST, id }),

    checkClaimItem: claim =>({ type: actions.CHECK_CLAIM_ITEM, claim}),

    selectCurrentClaim: id => ({ type: actions.SELECT_CURRENT_CLAIM, id }),
    selectCurrentDraftedClaim: id => ({ type: actions.SELECT_CURRENT_DRAFTED_CLAIM, id }),
    selectSubmittedOfficerCurrentClaim: id => ({ type: actions.SELECT_SUBMITTED_OFFICER_CURRENT_CLAIM, id }),
    selectSubmittedCurrentClaimForApprover: id => ({ type: actions.SELECT_SUBMITTED_CURRENT_CLAIM_FOR_APPROVER, id }),
    selectSubmittedCurrentClaimForHR: id => ({ type: actions.SELECT_SUBMITTED_CURRENT_CLAIM_FOR_HR, id }),
    selectDraftedCurrentClaim: id => ({ type: actions.SELECT_DRAFTED_CURRENT_CLAIM, id }),
    toggleView: view => ({ type: actions.TOGGLE_VIEW, view }),
    editClaim: claim => ({ type: actions.UPDATE_EDIT_CLAIM, claim }),
    clearState: () => ({ type: actions.CLEAR_STATE }),

    getSAPBatchReview: () => ({ type: actions.GET_SAP_BATCH }),
    getSAPBatchFile: () => ({ type: actions.GET_SAP_BATCH_WITH_ID }),
    getSAPBatchFileHistory: () => ({ type: actions.GET_SAP_BATCH_HISTORY }),
    getSAPBatchFileByID: id => ({ type: actions.GET_SAP_BATCH_HISTORY_FILE, id }),

    GET_WELLNESS_CLAIM:'GET_WELLNESS_CLAIM',
   
  
    

    GET_NOPAYLEAVE_DRAFTED: 'GET_NOPAYLEAVE_DRAFTED',
    initNoPayLeaveDraftedData: () => ({ type: actions.GET_NOPAYLEAVE_DRAFTED }),

    

  

    GET_WELLNESS_DUPLICATE: 'GET_WELLNESS_DUPLICATE',
    UPDATE_WELLNESS_DUPLICATE: 'UPDATE_WELLNESS_DUPLICATE',
    checkWellnessDuplicate: (detail, obj, callback) => ({ type: actions.GET_WELLNESS_DUPLICATE, detail, obj, callback }),

};
export default actions;
