import actions from './actions';

const initState = {
    loading: true,
    isError: false,
    wellnessList: [],
    myWellnessList:[],
    wellnessClaim:[],
    errorMsg:"",
    wellnessBalance: 0,
    wellnessItemsReport:[],
    SAPBatchList: [],
    SAPBatchHistoryList: [],
    wellnessBalanceByYear: 0,
    wellnessCheckReciept:false,
};

export default function myclaimReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.UPDATE_SUBMITTED_WELLNESS_LIST: {
            return {
                ...state,
                wellnessList: action.wellnessList,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }     
        case actions.UPDATE_MY_SUBMITTED_WELLNESS_LIST: {
            return {
                ...state,
                myWellnessList: action.myWellnessList,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }     
        case actions.UPDATE_WELLNESS_CLAIM_DETAIL: {
            return {
                ...state,
                wellnessClaim: action.wellnessClaim,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  
        case actions.UPDATE_WELLNESS_BALANCE:
            return {
                ...state,
                wellnessBalance: action.wellnessBalance ,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };  

        case actions.UPDATE_WELLNESS_BALANCE_BY_RECEIPT_DATE:
            return {
                ...state,
                wellnessBalanceByYear: action.wellnessBalanceByYear,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };  
        case actions.UPDATE_CLAIM_ITEMS_REPORT:
        return {
            ...state,
            wellnessItemsReport: action.wellnessItemsReport ,
            loading: action.loading,
            isError: action.isError,
            errorMsg: action.errorMsg,
        };   
        case actions.UPDATE_SAP_BATCH: {
            return {
                ...state,
                SAPBatchList: action.SAPBatchList,
                totalClaimAmount: action.TotalClaimAmount,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  

        case actions.UPDATE_SAP_BATCH_WITH_ID: {
            return {
                ...state,
                downloadContent: action.downloadContent,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }   

        case actions.UPDATE_SAP_BATCH_HISTORY: {
            return {
                ...state,
                SAPBatchHistoryList: action.SAPBatchHistoryList,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }  
        case actions.UPDATE_WELLNESS_CHECK_RECEIPT_DATE:
            return {
                ...state,
                wellnessCheckReciept: action.wellnessCheckReciept,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        default:
            return state;
    }
}