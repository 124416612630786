import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import customRoutes from '../../customApp/router';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Widgets/index.js')),
    },
    {
        path: 'logout',
        component: asyncComponent(() => import('../Page/signout.js')),
    },
    {
        path: 'form',
        component: asyncComponent(() => import('../Widgets/index.js')),
    },
  {
      path: 'home',
      component: asyncComponent(() => import('../Widgets/index.js')),
    },
    {
        path: 'newform',
        component: asyncComponent(() => import('../NewForm')),
    },
   
      {
          path: 'newTransportclaim/:claimId/:eeId',
          component: asyncComponent(() => import('../NewForm/transportform')),
    },
    {
        path: 'newWellnessClaim/:claimId/:typeId',
        component: asyncComponent(() => import('../NewForm/wellnessform')),
    },
    {
        path: 'newNoPayLeaveForm/:leaveId/:eeId',
        component: asyncComponent(() => import('../NewForm/nopayleaveform')),
    },
    {
        path: 'noeligible/:msg',
        component: asyncComponent(() => import('../Message/NoEligibleWellness')),
    },
    {
        path: 'submitted',
        component: asyncComponent(() => import('../SubmittedForm')),
    },
    {
        path: 'submitted/:form/:id',
        component: asyncComponent(() => import('../SubmittedForm/form')),
    },    
    {
        path: 'test',
        component: asyncComponent(() => import('../DraftClaim/test')),
    },
    {
        path: 'draftedClaim',
        component: asyncComponent(() => import('../DraftClaim')),
    },
    {
        path: 'draftedClaim/:claimId',
        component: asyncComponent(() => import('../DraftClaim/transportform')),
    },
    {
        path: 'submittedClaimOfficer',
        component: asyncComponent(() => import('../SubmittedClaimOfficer')),
    },
    {
        path: 'submittedClaimOfficer/:claimId',
        component: asyncComponent(() => import('../SubmittedClaimOfficer/form')),
    },
    {
        path: 'submittedPageApprover',
        component: asyncComponent(() => import('../SubmittedPageApproval')),
    },
    {
        path: 'submittedPageApprover/:form/:id',
        component: asyncComponent(() => import('../SubmittedPageApproval/form')),
    },
    {
        path: 'submittedPageHR',
        component: asyncComponent(() => import('../SubmittedPageProcess')),
    },
    {
        path: 'submittedPageHR/:form/:id',
        component: asyncComponent(() => import('../SubmittedPageProcess/form')),
    },
    {
        path: 'approvedHistory',
        component: asyncComponent(() => import('../ApprovedHistory')),
    },
    {
        path: 'approvedHistory/:form/:id',
        component: asyncComponent(() => import('../ApprovedHistory/form')),
    },
    {
        path: 'processedHistory',
        component: asyncComponent(() => import('../ProcessedHistory')),
    },
    {
        path: 'processedHistory/:form/:id',
        component: asyncComponent(() => import('../ProcessedHistory/form')),
    },
    {
        path: 'submittedClaimHR',
        component: asyncComponent(() => import('../SubmittedClaimHR')),
    },
    {
        path: 'submittedClaimHR/:claimId',
        component: asyncComponent(() => import('../SubmittedClaimHR/form')),
    },
    {
        path: 'approvedClaimForCO',
        component: asyncComponent(() => import('../ApprovedClaimOfficer')),
    },
    {
        path: 'approvedClaimForCO/:claimId',
        component: asyncComponent(() => import('../SubmittedClaimOfficer/form')),
    },
    {
        path: 'approvedClaimForApprover',
        component: asyncComponent(() => import('../ApprovedClaimApprover')),
    },
    {
        path: 'approvedClaimForApprover/:claimId',
        component: asyncComponent(() => import('../SubmittedPageApproval/form')),
    },
    {
        path: 'approvedClaimForHR',
        component: asyncComponent(() => import('../ApprovedClaimHR')),
    },
    {
        path: 'approvedClaimForHR/:claimId',
        component: asyncComponent(() => import('../SubmittedClaimHR/form')),
    },
    {
        path: 'reportWellnessClaim',
        component: asyncComponent(() => import('../Report/WellnessClaimReport')),
    },
    {
        path: 'reportSharedServices',
        component: asyncComponent(() => import('../Report/NoPayLeaveSharedServices')),
    },
    {
        path: 'reportForHR',
        component: asyncComponent(() => import('../Report')),
    },
    {
        path: 'reportForHR/:claimId',
        component: asyncComponent(() => import('../Report/form')),
    },
    {
        path: 'checkingApproverSetting',
        component: asyncComponent(() => import('../SettingCheckingOfficer')),
    },
    {
        path: 'approverSetting',
        component: asyncComponent(() => import('../SettingApprover')),
    },
    {
        path: 'divisionHeadSetting',
        component: asyncComponent(() => import('../SettingDivisionHead')),
    },
    {
        path: 'hrSetting',
        component: asyncComponent(() => import('../SettingHR/hrList')),
    },
    {
        path: 'addHR',
        component: asyncComponent(() => import('../SettingHR')),
    },

    {
        path: 'adminSetting',
        component: asyncComponent(() => import('../SettingAdmin/adminList')),
    },
    {
        path: 'addAdmin',
        component: asyncComponent(() => import('../SettingAdmin')),
    },
    {
        path: 'employeeSetting',
        component: asyncComponent(() => import('../SettingEmployees')),
    },
    {
        path: 'employeeSetting/:id/:tmpWellness/:allowLateClaim',
        component: asyncComponent(() => import('../SettingEmployees/employeeDetail')),
    },
    {
        path: 'signInWithPassword',
        component: asyncComponent(() => import('../Page/signinPassword')),
    },
    {
        path: 'report1',
        component: asyncComponent(() => import('../Report/report1')),
    },
    {
        path: 'generateSAPBatchFile',
        component: asyncComponent(() => import('../SAP')),
    },
    {
        path: 'getBatchFileHistory',
        component: asyncComponent(() => import('../SAP/batchFileHistory')),
    },
    {
        path: 'view',
        component: asyncComponent(() => import('../Page/view.js')),
    },
    {
        path: 'faq',
        component: asyncComponent(() => import('../FAQ')),
    },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
