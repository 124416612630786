import axios from 'axios'
import moment from 'moment';
import { call, all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import URL, { localStorageConfig } from '../../settings';
import actions from './actions';

export function* getNoPayLeaveByID({ detail, obj, callback}) {
    yield put({
        type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
        leave: [],
        loading: true,
        isError: false,
    });

    if (detail != undefined) {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'NoPayLeave/' + detail, config)

        yield put({
            type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
            leave: data[0],
            loading: false,
            isError: false,
        });

        callback(data[0], obj);
    }
    else {
        yield put({
            type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
            leave: [],
            loading: false,
            isError: false,
        });
    }
}

export function* submitLeave({ leave, obj, callback  }) {
   
    if (leave != undefined) {
        try {           
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'NoPayLeave/', leave, config)
       
            if (data=="") {
                yield put({
                    type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
                    leave: data,
                    loading: false,
                    isError: false,
                });
                callback(data, obj);
            }
            else {
                if (data.includes("Duplicate leave")) {
                    yield put({
                        type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
                        leave: data,
                        loading: false,
                        isError: true,
                        errorMsg: data,
                    });
                }
            }


        }
        catch (e) {
            yield put({
                type: actions.UPDATE_SUBMIT_leave,
                isSubmitted: false,
                leave: [],
                loading: false,
                isError: true,
            });
        }
    }
}

export function* updateLeave({ leave, obj, callback  }) {
   
    if (leave != undefined) {
        try {           
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'NoPayLeave/UpdateNoPayLeave', leave, config)
       
            if (data.id != undefined) {
                yield put({
                    type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
                    leave: data,
                    loading: false,
                    isError: false,
                });
                callback(data[0], obj);
            }
            else {
                if (data.includes("Duplicate leave")) {
                    yield put({
                        type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
                        leave: data,
                        loading: false,
                        isError: true,
                        errorMsg: data,
                    });
                }
            }


        }
        catch (e) {
            yield put({
                type: actions.UPDATE_NO_PAY_LEAVE_DETAIL,
                isSubmitted: false,
                leave: [],
                loading: false,
                isError: true,
            });
        }
    }
}

export function* checkAvailableDate({ eeId }) {    

    if (eeId != undefined) {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'NoPayLeave/CheckDateAvailable/' + eeId, config)

        yield put({
            type: actions.UPDATE_CHECK_AVAILABLE_DATE,
            msgLeave: data.msgLeave,
            leaveTakenList: data.leaveTakenList,
            msgBlock: data.msgBlock,
            blockList: data.blockList,
            msgEndBlock: data.msgEndBlock,
            blockEndList: data.blockEndList,
            loading: false,
            isError: false,
        });
    }
    else {
        yield put({
            type: actions.UPDATE_CHECK_AVAILABLE_DATE,
            msgLeave: [],
            leaveTakenList: [],
            msgBlock: [],
            blockList: [],
            msgEndBlock: [],
            blockEndList:[],
            loading: false,
            isError: false,
        });
    }
}

export function* getLeaveList({ leave }) {
       
    const localData = leave.onBehalf != undefined && leave.onBehalf != "0" ? leave.onBehalf : localStorage.getItem(localStorageConfig.curUserGUID);

    let newDate;
    let cMonth;
    let cYear;
    if (leave.monthyearfromto != null && leave.monthyearfromto != undefined && leave.monthyearfromto != "") {
        newDate = leave.monthyearfromto;
        cMonth = newDate.month() + 2;
        cYear = newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2;
        cYear = newDate.getFullYear();
    }


    let last6Month;
    if (leave.monthyearfrom != null && leave.monthyearfrom != undefined && leave.monthyearfrom != "") {
        last6Month = leave.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(6, 'M');
    }
    let sMonth = last6Month.month() + 1 == 13 ? 1 : last6Month.month() + 1;    
    let sYear = last6Month.month() + 1 == 13 ? last6Month.year() + 1 : last6Month.year();
    let status = leave.statusSeleted == "" || leave.statusSeleted == undefined ? "0" : leave.statusSeleted
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'NoPayLeave/GetMyList/' + localData + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/' + status, config)

        yield put({
            type: actions.UPDATE_LEAVE_LIST,
            myList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_LEAVE_LIST,
            myList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getPendingApprovalLeaveList({ leave }) {
       
    let newDate;
    let cMonth;
    let cYear;
    if (leave.monthyearfromto != null && leave.monthyearfromto != undefined && leave.monthyearfromto != "") {
        newDate = leave.monthyearfromto;
        cMonth = newDate.month() + 2;
        cYear = newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2;
        cYear = newDate.getFullYear();
    }


    let last6Month;
    if (leave.monthyearfrom != null && leave.monthyearfrom != undefined && leave.monthyearfrom != "") {
        last6Month = leave.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(12, 'M');
    }
    let sMonth = last6Month.month() + 1 == 13 ? 1 : last6Month.month() + 1;
    let sYear = last6Month.month() + 1 == 13 ? last6Month.year() + 1 : last6Month.year();
    let status = leave.statusSeleted == "" || leave.statusSeleted == undefined ? "0" : leave.statusSeleted;
    let isSubmit = leave.isSubmit;
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'NoPayLeave/GetPendingApprovalList/' + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + "/" + isSubmit+ '/' + status, config)

        yield put({
            type: actions.UPDATE_LEAVE_LIST,
            myList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_LEAVE_LIST,
            myList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getLeaveBalance() {    

        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'NoPayLeave/GetLeaveBalance', config)

        yield put({
            type: actions.UPDATE_LEAVE_BALANCE,
            leaveBalance: data,          
            loading: false,
            isError: false,
        });
   
}

export function* checkHRBPRight({ leave }) {    

    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const { data } = yield call(axios.get, URL.apiUrl + 'NoPayLeave/CheckHRBPRight/'+ leave, config)

    yield put({
        type: actions.UPDATE_HRBP_RIGHT,
        isLeaveHRBP: data,          
        loading: false,
        isError: false,
    });

}
export default function* rootSaga() {    
    yield all([
        yield takeEvery(actions.GET_NO_PAY_LEAVE_DETAIL, getNoPayLeaveByID),
        yield takeEvery(actions.SUBMIT_LEAVE, submitLeave),
        yield takeEvery(actions.UPDATE_LEAVE, updateLeave),
        yield takeEvery(actions.CHECK_AVAILABLE_DATE, checkAvailableDate),
        yield takeEvery(actions.GET_LEAVE_LIST, getLeaveList),
        yield takeEvery(actions.GET_PENDING_APPROVAL_LEAVE_LIST, getPendingApprovalLeaveList),
        yield takeEvery(actions.GET_LEAVE_BALANCE, getLeaveBalance),
        yield takeEvery(actions.CHECK_HRBP_RIGHT, checkHRBPRight),
    ]);
}