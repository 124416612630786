import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import axios from 'axios'
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import URL, { localStorageConfig } from '../../settings';

const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
    yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
        const { token } = payload;
        const localToken = localStorage.getItem(localStorageConfig.id_token);
        if (token) {
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            try {
                const detail = yield call(axios.get, URL.apiUrl + 'Employee/GetEmployeeById', config)
                if (detail.data != null) {
                    yield put({
                        type: actions.LOGIN_SUCCESS,
                        token: localToken,
                        employeeDetail: detail.data[0],
                        profile: 'Profile',
                    });
                    localStorage.setItem(localStorageConfig.curUserGUID, detail.data[0].id);

                    //  localStorage.setItem(localStorageConfig.employeeDetail, JSON.stringify(detail.data[0]));      
                }

                const claimrate = yield call(axios.get, URL.apiUrl + 'Master/GetClaimRate', config)
                if (claimrate.data != null) {
                    localStorage.setItem(localStorageConfig.claimRate, claimrate.data);
                }
                localStorage.setItem(localStorageConfig.transportClaimID, 'C79F6712-1261-4F30-B54D-0CB584AAFEC5');
                localStorage.setItem(localStorageConfig.wellnessClaimID, '7D13CAD7-7F94-4A35-99A2-61B547733EDE');
                localStorage.setItem(localStorageConfig.noPayLeaveID, '11966525-C10A-4843-8BED-CD9FFE372AF2');
                localStorage.setItem(localStorageConfig.dentalID, 'B43DEF56-E59B-4A2A-9570-DDDEB2A0896C');
                localStorage.setItem(localStorageConfig.opticalID, 'DE67AE0D-2733-407E-AF70-8D4CBDE3ACE4');
                localStorage.setItem(localStorageConfig.medicalID, 'E190F03B-1881-46CE-BD3D-E61660EF33BB');

            }
            catch (e) {
                window.location.href = URL.apiUrl + "employee";
              //  window.location.href ="http://ehrform.cpgcorp.com.sg/eformws/api/employee"
            }
        }
    });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
      yield put(push('/signout'));
  });
}
export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const token = getToken().get('idToken');
        if (token) {            
            yield put({
                type: actions.LOGIN_SUCCESS,
                token,                
                profile: 'Profile',
            });          
        }
    });
}

export function* getAuthentication() {
    try {
        const config = {
            headers: {
                //'withCredentials': true,
                //'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        window.location.href = URL.apiUrl + "employee";
        //window.location.href = "http://ehrform.cpgcorp.com.sg/eformws/api/employee"
        const { data } = yield call(axios.get, URL.apiUrl + 'Employee', config)
        localStorage.setItem(localStorageConfig.curUserName, data.name);
    }
    catch (e) {
        window.location.href = URL.apiUrl + "employee";
       // window.location.href = "http://ehrform.cpgcorp.com.sg/eformws/api/employee"
    }

}

export function* getEmployeeDetail() {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
    const detail = yield call(axios.get, URL.apiUrl + 'Employee/GetEmployeeById', config)
    if (detail.data != null) {
        yield put({
            type: actions.UPDATE_EMPLOYEE_DETAIL,
            employeeDetail: detail.data[0],
        });
    }
    }
    catch (e) {
        window.location.href = URL.apiUrl + "employee";
       // window.location.href = "http://ehrform.cpgcorp.com.sg/eformws/api/employee"
    }
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
      fork(logout),
      yield takeEvery(actions.GET_TOKEN, getAuthentication),
      yield takeEvery(actions.GET_EMPLOYEE_DETAIL, getEmployeeDetail),   
  ]);
}
