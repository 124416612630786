import React, { Component } from 'react';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import IntlMessages from '../../components/utility/intlMessages';
import Scrollbar from '../../components/utility/customScrollBar';
import TopbarDropdownWrapper from './topbarDropdown.style';
import masterActions from '../../redux/master/actions';

const { getNotification } = masterActions;
const demoNotifications = [
  {
    id: 1,
        name: 'Claim Id : 1518713981654',
    notification:
      'Your claim has been approved by Andy Tan',
  },
  {
    id: 2,
      name: 'Claim Id : 1518713981654',
    notification:
      'A new claim submitted by Nicolas Chang',
  },
  {
    id: 3,
      name: 'Claim Id : 1518713981655',
    notification:
      'Your claim has been rejected by Henry Ong',
  },
  
];



class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
    componentDidMount() {
        const { getNotification} = this.props;
        getNotification();
    }

   

  render() {
      const { customizedTheme, notification } = this.props;
   
     const handleGo = (url) => {
         const path = { pathname: '/form/' + url };
         //alert(window.location.origin);
         window.location.href = window.location.origin + '/form/' + url;
          //return <Redirect to={path} />
      };

      const content = (
          <TopbarDropdownWrapper className="topbarNotification">
              <div className="isoDropdownHeader">
                  <h3>
                      <IntlMessages id="sidebar.notification" />
                  </h3>
              </div>
              <div className="isoDropdownBody">
                  <Scrollbar style={{ height: 300 }}>
                      {notification.map(notification => (
                          /*   <a className="isoDropdownListItem" key={notification.id} href={notification.url}>*/
                          <a className="isoDropdownListItem" key={notification.id} onClick={handleGo.bind(this, notification.url)}>
                              {/*  <Link to={notification.url} className="btn btn-primary">hello</Link>*/}
                              <h5>{notification.title}</h5>
                              <p>{notification.description}</p>
                          </a>
                      ))}
                  </Scrollbar>
              </div>
              {/*<a className="isoViewAllBtn" href="# ">*/}
              {/*  <IntlMessages id="topbar.viewAll" />*/}
              {/*</a>*/}
          </TopbarDropdownWrapper>
      );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">
          <i
            className="ion-android-notifications"
            style={{ color: customizedTheme.textColor }}
          />
                <span>{notification.length}</span>
        </div>
      </Popover>
    );
  }
}

export default connect(state => ({
    ...state.App, ...state.Master,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
}), { getNotification })(TopbarNotification);
