import clone from 'clone';
import actions from './actions';

const initState = {
    loading: true,
    isError: false,
    errorMsg: "",    
    reportDetail: [],   
};

export default function reportReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.UPDATE_TOTAL_AMOUNT: {
            return {
                ...state,
                reportDetail: action.reportDetail,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        }      
        default:
            return state;
    }
}
