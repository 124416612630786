import clone from 'clone';
import actions from './actions';

const initState = {
    loading: true,
    isError: false,
    leave: [],
    errorMsg: "",
    msgLeave: "",
    leaveTakenList: [],
    msgBlock: "",
    blockList: [],
    msgEndBlock:"",
    blockEndList: [],
    myList:[],
    leaveBalance:[],
    isLeaveHRBP:false,
};

export default function myclaimReducer(state = initState, { type, ...action }) {
    switch (type) {
        case actions.UPDATE_NO_PAY_LEAVE_DETAIL:
            return {
                ...state,
                leave: action.leave,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };

        case actions.UPDATE_CHECK_AVAILABLE_DATE:
            return {
                ...state,
                msgLeave: action.msgLeave,
                leaveTakenList: action.leaveTakenList,
                msgBlock: action.msgBlock,
                blockList: action.blockList,
                msgEndBlock: action.msgEndBlock,
                blockEndList: action.blockEndList,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
        case actions.UPDATE_LEAVE_LIST:
            return {
                ...state,
                myList: action.myList,
                loading: action.loading,
                isError: action.isError,
                errorMsg: action.errorMsg,
            };
            case actions.UPDATE_LEAVE_BALANCE:
                return {
                    ...state,
                    leaveBalance: action.leaveBalance,
                    loading: action.loading,
                    isError: action.isError,
                    errorMsg: action.errorMsg,
                };
                case actions.UPDATE_HRBP_RIGHT:
                    return {
                        ...state,
                        isLeaveHRBP: action.isLeaveHRBP,
                        loading: action.loading,
                        isError: action.isError,
                        errorMsg: action.errorMsg,
                    };
        default:
            return state;
    }
}