const actions = {
    GET_UPLOADED_DOCUMENT: 'GET_UPLOADED_DOCUMENT',
    UPDATE_UPLOADED_DOCUMENT: 'UPDATE_UPLOADED_DOCUMENT',
    DELETE_UPLOADED_DOCUMENT: 'DELETE_UPLOADED_DOCUMENT',
    DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
    PREVIEW_DOCUMENT: 'PREVIEW_DOCUMENT',
    UPDATE_PREVIEW_LATE_DOCUMENT: 'UPDATE_PREVIEW_LATE_DOCUMENT',

    getUploadedDocument: id => ({ type: actions.GET_UPLOADED_DOCUMENT, id }),
    deleteUploadedDocument: (id, detailID, claimID, obj, callback) => ({ type: actions.DELETE_UPLOADED_DOCUMENT, id, detailID, claimID, obj, callback }),
    downloadUploadedDocument: id => ({ type: actions.DOWNLOAD_DOCUMENT, id }),
    previewUploadedDocument: id => ({ type: actions.PREVIEW_DOCUMENT, id }),
};
export default actions;