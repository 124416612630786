export default {
 //   apiUrl: 'http://corpdev2018:8081/api/'
 //apiUrl: 'http://corpuat2018:201/eformws/api/',
 //apiUrl: 'https://eservices.cpgcorp.com.sg/eformws/api/'
  //   apiUrl: 'http://corpuat2018:201/eformws1/api/'
  //  apiUrl: 'https://eservices.cpgcorp.com.sg/eformws1/api/'
  // apiUrl: 'https://localhost:44315/api/'
  apiUrl:'https://ehrform.cpgcorp.com.sg/eformws/api/'
};

const d = new Date();
let year = d.getFullYear();

const siteConfig = {
    siteName: 'e-HR Form',
  siteIcon: '', //ion-flash
  footerText: 'Copyright @ ' + year +' CPG Corporation Pte Ltd. All rights reserved.'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};
const Auth0Config = {
  domain: '',
  clientID: '',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'http://localhost:3000/auth0loginCallback'
    },
    languageDictionary: {
      title: 'Isomorphic',
      emailInputPlaceholder: 'demo@gmail.com',
      passwordInputPlaceholder: 'demodemo'
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#E14615',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000'
        }
      }
    }
  }
};
const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: ''
};
const googleConfig = {
  apiKey: '' //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: []
};
const youtubeSearchApi = '';

const localStorageConfig ={
    curUserName:'curUserName',
    curUserID: 'curUserID',
    curUserGUID: 'curUserGUID',
    id_token: 'id_token',
    employeeDetail: 'employeeDetail',
    claimRate: 'claimRate',
    transportClaimID: 'transportClaimID',
    wellnessClaimID: 'wellnessClaimID',
    noPayLeaveID: 'noPayLeaveID',
    dentalID: 'dentalID',
    opticalID: 'opticalID',
    medicalID:'medicalID',
}
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
    youtubeSearchApi,
    localStorageConfig
};
