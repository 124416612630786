const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    GET_EMPLOYEE_DETAIL: 'GET_EMPLOYEE_DETAIL',
    UPDATE_EMPLOYEE_DETAIL:'UPDATE_EMPLOYEE_DETAIL',
    GET_TOKEN:'GET_TOKEN',
    getAuthentication: () => ({ type: actions.GET_TOKEN }),
   
    checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
    login: (token = false) => ({
        type: actions.LOGIN_REQUEST,
        payload: { token },
    }),
    logout: () => ({
        type: actions.LOGOUT,
    }),

    getEmployeeDetail: () => ({ type: actions.GET_EMPLOYEE_DETAIL }),
};
export default actions;
