import axios from 'axios'
import moment from 'moment';
import { call, all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import URL, { localStorageConfig } from '../../settings';
import actions from './actions';

export function* getSubmittedWellnessList({claim }) {       

    const localData = claim.onBehalf != undefined && claim.onBehalf != "0" ? claim.onBehalf: localStorage.getItem(localStorageConfig.curUserGUID);

    let newDate;
    let cDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cDate = newDate.date();
        cMonth = newDate.month() + 1 == 13 ? 1 : newDate.month() + 1;
        cYear = newDate.month() + 1 == 13 ? newDate.year() + 1 : newDate.year();
    }
    else {
        newDate = new Date();
        cDate = new Date(newDate.getFullYear(), newDate.getMonth() + 2, 0).getDate();
        cMonth = newDate.getMonth() + 2 == 13 ? 1 : newDate.getMonth() + 2;
        cYear = newDate.getMonth() + 2 == 13 ? newDate.getFullYear() + 1 : newDate.getFullYear();
    }


    let last6Month;
    let sDate;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
        sDate = claim.monthyearfrom.date();
    }
    else {
        last6Month = moment().subtract(6, 'M');
        sDate = 1;
    }
   
    let sMonth = last6Month.month() + 1 == 13 ? 1 : last6Month.month() + 1;
    let sYear = last6Month.month() + 1 == 13 ? last6Month.year() + 1 : last6Month.year();
    let status = claim.statusSeleted == "" || claim.statusSeleted == undefined ? "0" : claim.statusSeleted
    let processType = claim.processType == "" || claim.processType == undefined ? "0" : claim.processType
    let isSubmit = claim.isSubmit == "" || claim.isSubmit == undefined ? "0" : claim.isSubmit
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetWellnessList/' + localData + "/" + sDate + "/" + cDate + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/' + status + '/' + processType + "/" + isSubmit, config)
        yield put({
            type: actions.UPDATE_SUBMITTED_WELLNESS_LIST,
            wellnessList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SUBMITTED_WELLNESS_LIST,
            wellnessList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getMySubmittedWellnessList({claim }) {       

    const localData = claim.onBehalf != undefined && claim.onBehalf != "0" ? claim.onBehalf: localStorage.getItem(localStorageConfig.curUserGUID);

    let newDate;
    let cDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
        cMonth = newDate.getMonth() + 2 == 13 ? 1 : newDate.getMonth() + 2;
        cYear = newDate.getMonth() + 2 == 13 ? newDate.getFullYear() + 1 : newDate.getFullYear();
    }
    else {
        newDate = new Date();
        cDate = new Date(newDate.getFullYear(), newDate.getMonth() + 2, 0).getDate();
        cMonth = newDate.getMonth() + 2 == 13 ? 1 : newDate.getMonth() + 2;
        cYear = newDate.getMonth() + 2 == 13 ? newDate.getFullYear() + 1 : newDate.getFullYear();
    }


    let last6Month;
    let sDate;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
        sDate = claim.monthyearfrom.date();
    }
    else {
        last6Month = moment().subtract(6, 'M');
        sDate = 1;
    }   
    let sMonth = last6Month.month() + 1 == 13 ? 1 : last6Month.month() + 1;
    let sYear = last6Month.month() + 1 == 13 ? last6Month.year() + 1 : last6Month.year();
    let status = "2";
    let processType = claim.processType == "" || claim.processType == undefined ? "0" : claim.processType
    let isSubmit = claim.isSubmit == "" || claim.isSubmit == undefined ? "0" : claim.isSubmit
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetWellnessList/' + localData + "/" + sDate + "/" + cDate + "/" + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/' + status + '/' + processType + "/" + isSubmit, config)
        yield put({
            type: actions.UPDATE_MY_SUBMITTED_WELLNESS_LIST,
            myWellnessList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_MY_SUBMITTED_WELLNESS_LIST,
            myWellnessList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getWellnessClaimByID({ detail, obj, callback }) {
    if (detail != undefined) {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/' + detail, config)

        yield put({
            type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
            wellnessClaim: data[0],
            loading: false,
            isError: false,
        });
        callback(data[0], obj);
    }
    else {
        yield put({
            type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
            wellnessClaim: [],
            loading: false,
            isError: false,
        });
    }


}

export function* getWellnessDraftedClaim() {   

    const localData = localStorage.getItem(localStorageConfig.curUserGUID);
    const token = localStorage.getItem(localStorageConfig.id_token);
    let newDate = new Date()
    let cDate = new Date(newDate.getFullYear(), newDate.getMonth() + 2, 0).getDate();
    let cMonth = newDate.getMonth() + 2 == 13 ? 1 : newDate.getMonth() + 2;
    let cYear = newDate.getMonth() + 2 == 13 ? newDate.getFullYear() + 1 : newDate.getFullYear();
    const last6Month = moment().subtract(6, 'M');
    let sDate = 1;
   
    let sMonth = last6Month.month();
    let sYear = last6Month.year();
    if (sMonth == 0) {
        sMonth = 1;
    }
    try {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetWellnessList/' + localData + "/" + sDate + "/" + cDate + "/"  + sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + '/1/0/3', config)

        yield put({
            type: actions.UPDATE_SUBMITTED_WELLNESS_LIST,
            wellnessList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SUBMITTED_WELLNESS_LIST,
            wellnessList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* deleteWellnessDraftedClaim({ id }) {
    if (id != undefined) {
        try {
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.delete, URL.apiUrl + 'Wellness/' + id, config)

            yield put({
                type: actions.UPDATE_SUBMITTED_WELLNESS_LIST,
                wellnessList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_SUBMITTED_WELLNESS_LIST,
                wellnessList: [],
                loading: false,
                isError: true,
            });
        }
    }
}

export function* updateWellnessClaim({ detail, obj, callback  }) {

    if (detail != undefined) {
        try {          
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'Wellness/UpdateClaim', detail, config)
            yield put({
                type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
                wellnessClaim: data,
            loading: false,
            isError: false,
            });
            callback(data, obj);
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
                wellnessClaim: [],
            loading: false,
            isError: false,
            });
        }
    }
}

export function* getWellnessBalance() {
    yield put({
        type: actions.UPDATE_WELLNESS_BALANCE,
        wellnessBalance: 0,
        loading: true,
        isError: false,
    });

    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetWellnessClaimBalance', config)

    yield put({
        type: actions.UPDATE_WELLNESS_BALANCE,
        wellnessBalance: data,
        loading: false,
        isError: false,
    });
}

export function* getWellnessBalanceByID({ detail}) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetWellnessClaimBalanceByEmployee/' + detail, config)

    yield put({
        type: actions.UPDATE_WELLNESS_BALANCE,
        wellnessBalance: data,
        loading: false,
        isError: false,
    });
}

export function* getWellnessBalanceByReceiptDate({ eeID, year, obj, callback  }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetWellnessClaimBalanceByEmployeeAndYear/' + eeID + '/' + year, config)

    yield put({
        type: actions.UPDATE_WELLNESS_BALANCE_BY_RECEIPT_DATE,
        wellnessBalanceByYear: data,
        loading: false,
        isError: false,
    });

    callback(data, obj);
}

export function* getWellnessClaimReport({ claim}) {

    let newDate;
    let cMonth;
    let cYear;
    if (claim.monthyearfromto != null && claim.monthyearfromto != undefined && claim.monthyearfromto != "") {
        newDate = claim.monthyearfromto;
        cMonth = newDate.month() + 2 == 13 ? 1 : newDate.month() + 2;
        cYear = newDate.month() + 2 == 13 ? newDate.year() + 1 : newDate.year();
    }
    else {
        newDate = new Date();
        cMonth = newDate.getMonth() + 2 == 13 ? 1 : newDate.getMonth() + 2 ;
        cYear = newDate.getMonth() + 2 == 13 ? newDate.getFullYear() + 1 : newDate.getFullYear();
    }


    let last6Month;
    if (claim.monthyearfrom != null && claim.monthyearfrom != undefined && claim.monthyearfrom != "") {
        last6Month = claim.monthyearfrom;
    }
    else {
        last6Month = moment().subtract(12, 'M');
    }
    let sMonth = last6Month.month() + 1 == 13 ? 1 : last6Month.month() + 1;
    let sYear = last6Month.month() + 1 == 13 ? last6Month.year() + 1 : last6Month.year();

    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    //const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetWellnessClaimReport/' + claim.company + "/"
    //                            + claim.division + "/" +  sMonth + "/" + cMonth + "/" + sYear + "/" + cYear + "/"
    //    + claim.eeID + "/" + claim.eeName + "/" + claim.statusSeleted, config)

    var detail = {
        company: claim.company,
        division: claim.division,
        mFrom: sMonth,
        mTo: cMonth,
        yFrom: sYear,
        yTo: cYear,
        eeNo: claim.eeID,
        eeName: claim.eeName,
        status: claim.statusSeleted
    };
    const { data } = yield call(axios.post, URL.apiUrl + 'Wellness/GetWellnessClaimReport', detail, config)

    yield put({
        type: actions.UPDATE_CLAIM_ITEMS_REPORT,
        wellnessItemsReport: data,
        loading: false,
        isError: false,
    });
}

export function* getSAPBatchReview() {
    try {
        const token = localStorage.getItem(localStorageConfig.id_token);
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GenerateBatchDetail', config)
        yield put({
            type: actions.UPDATE_SAP_BATCH,
            SAPBatchList: JSON.parse(data.ClaimList),
            TotalClaimAmount: data.TotalBatchAmount,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SAP_BATCH,
            SAPBatchList: [],
            loading: false,
            isError: true,
            errorMsg: "Error.",
        });
    }
}

export function* getSAPBatchFile() {
    try {
        const token = localStorage.getItem(localStorageConfig.id_token);
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GenerateBatchFile', config)

        if (data != null) {
            try {

                axios({
                    url: URL.apiUrl + 'Wellness/DownloadBatchFile/' + data, //your url
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "Claims_" + data+ ".txt"); //or any other extension
                    document.body.appendChild(link);
                    link.click();    
                });

                const detail = yield call(axios.get, URL.apiUrl + 'Wellness/GenerateBatchDetail', config)
                yield put({
                    type: actions.UPDATE_SAP_BATCH,
                    SAPBatchList: JSON.parse(detail.data.ClaimList),
                    TotalClaimAmount: detail.data.TotalBatchAmount,
                    loading: false,
                    isError: false,
                    errorMsg: "",
                });
                //yield put({
                //    type: actions.UPDATE_SAP_BATCH,
                //    SAPBatchHistoryList: [],
                //    loading: false,
                //    isError: false,
                //    errorMsg: "",
                //});
            }
            catch (e) {
                yield put({
                    type: actions.UPDATE_SAP_BATCH_WITH_ID,
                    downloadContent: {},
                    loading: false,
                    isError: true,
                    errorMsg: "Error.",
                });
            }
        }

    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SAP_BATCH_WITH_ID,
            downloadContent: {},
            loading: false,
            isError: true,
            errorMsg: "Error.",
        });
    }
}

export function* getSAPBatchFileHistory() {
    try {
        const token = localStorage.getItem(localStorageConfig.id_token);
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/GetBatchFileHistory', config)
        yield put({
            type: actions.UPDATE_SAP_BATCH_HISTORY,
            SAPBatchHistoryList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_SAP_BATCH_HISTORY,
            SAPBatchHistoryList: [],
            loading: false,
            isError: true,
            errorMsg: "Error.",
        });
    }
}

export function* getSAPBatchFileByID({ id }) {
    if (id != undefined) {
        try {
            axios({
                url: URL.apiUrl + 'Wellness/DownloadBatchFile/' + id, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "Claims_" + id + ".txt"); //or any other extension
                document.body.appendChild(link);
                link.click();
            });            
        }
        catch (e) {
          
        }
    }
}

export function* submitWellnessClaim({ claim, obj, callback}) {
   
    if (claim != undefined) {
        try {
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'Wellness/', claim, config)

            if (data.id != undefined) {

                const detail = yield call(axios.get, URL.apiUrl + 'Wellness/' + data.id, config)

                yield put({
                    type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
                    wellnessClaim: detail,
                    loading: false,
                    isError: false,
                });
                callback("", obj);
            }
            else {
                if (data.includes("Duplicate claim")) {
                    yield put({
                        type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
                        wellnessClaim: data,
                        loading: false,
                        isError: true,
                        errorMsg: data,
                    });
                    callback("Duplicate claim", obj);
                }
            }
           
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
                wellnessClaim: [],
                loading: false,
                isError: true,
            });
        }
    }

}

export function* saveWellnessClaim({ claim, obj, callback }) {

    if (claim != undefined) {
        try {
            const config = {
                headers: {
                    'withCredentials': true,
                    'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
                }
            };
            const { data } = yield call(axios.post, URL.apiUrl + 'Wellness/SaveAsDraft', claim, config)
            yield put({
                type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
                isSave: true,
                claim: data,
                loading: false,
                isError: false,
            });
            callback("", obj);
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_WELLNESS_CLAIM_DETAIL,
                isSave: false,
                claim: [],
                loading: false,
                isError: true,
            });
        }
    }
}
export function* checkWellnessReceiptDate(detail) {
   
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    var d = detail.detail.format("DD-MM-YYYY")
    const { data } = yield call(axios.get, URL.apiUrl + 'Wellness/CheckReceiptDate/' + d, config)

    yield put({
        type: actions.UPDATE_WELLNESS_CHECK_RECEIPT_DATE,
        wellnessCheckReciept: data,
        loading: false,
        isError: false,
    });

    detail.callback(data, detail.obj);

}


export default function* rootSaga() {    
    yield all([
        yield takeEvery(actions.GET_SUBMITTED_WELLNESS_LIST, getSubmittedWellnessList),
        yield takeEvery(actions.GET_MY_SUBMITTED_WELLNESS_LIST, getMySubmittedWellnessList),
        yield takeEvery(actions.GET_WELLNESS_CLAIM_DETAIL, getWellnessClaimByID),
        yield takeEvery(actions.GET_WELLNESS_DRAFTED_CLAIM, getWellnessDraftedClaim),
        yield takeEvery(actions.UPDATE_WELLNESS_CLAIM_HR, updateWellnessClaim),
        yield takeEvery(actions.DELETE_WELLNESS_DRAFTED_CLAIM, deleteWellnessDraftedClaim),
        yield takeEvery(actions.GET_WELLNESS_BALANCE, getWellnessBalance),
        yield takeEvery(actions.GET_WELLNESS_BALANCE_BY_ID, getWellnessBalanceByID),
        yield takeEvery(actions.GET_WELLNESS_BALANCE_BY_RECEIPT_DATE, getWellnessBalanceByReceiptDate),
        yield takeEvery(actions.GET_CLAIM_ITEMS_REPORT, getWellnessClaimReport),
        yield takeEvery(actions.GET_SAP_BATCH, getSAPBatchReview),
        yield takeEvery(actions.GET_SAP_BATCH_WITH_ID, getSAPBatchFile),
        yield takeEvery(actions.GET_SAP_BATCH_HISTORY, getSAPBatchFileHistory),
        yield takeEvery(actions.GET_SAP_BATCH_HISTORY_FILE, getSAPBatchFileByID),

        yield takeEvery(actions.SUBMIT_WELLNESS_CLAIM, submitWellnessClaim),
        yield takeEvery(actions.SAVE_WELLNESS_CLAIM, saveWellnessClaim),
        yield takeEvery(actions.GET_WELLNESS_CHECK_RECEIPT_DATE, checkWellnessReceiptDate),
    ]);
}