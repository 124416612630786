const actions = {
    GET_TYPE_BY_TYPE_NAME: 'GET_TYPE_BY_TYPE_NAME',  
    UPDATE_TYPE_BY_TYPE_NAME: 'UPDATE_TYPE_BY_TYPE_NAME',
    initTypeByTypeName: type => ({ type: actions.GET_TYPE_BY_TYPE_NAME, type }),  

    GET_MASTER_OPTIONS: 'GET_MASTER_OPTIONS',
    UPDATE_MASTER_OPTIONS: 'UPDATE_MASTER_OPTIONS',
    intMasterOptions: () => ({ type: actions.GET_MASTER_OPTIONS }),  

    GET_DASHBOARD_DETAIL: 'GET_DASHBOARD_DETAIL',
    UPDATE_DASHBOARD_DETAIL: 'UPDATE_DASHBOARD_DETAIL',
    initDashboardDetail: () => ({ type: actions.GET_DASHBOARD_DETAIL }),  

    GET_EMPLOYEE_ROLE: 'GET_EMPLOYEE_ROLE',
    UPDATE_EMPLOYEE_ROLE: 'UPDATE_EMPLOYEE_ROLE',
    initEmployeeRole: (obj, callback) => ({ type: actions.GET_EMPLOYEE_ROLE , obj, callback }),  

    GET_NOTIFICATION: 'GET_NOTIFICATION',
    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
    getNotification: () => ({ type: actions.GET_NOTIFICATION }),
    deleteNotification: id => ({ type: actions.DELETE_NOTIFICATION, id }),

    GET_CLAIM_TYPE: 'GET_CLAIM_TYPE',
    UPDATE_CLAIM_TYPE: 'UPDATE_CLAIM_TYPE',
    getClaimType: () => ({ type: actions.GET_CLAIM_TYPE }),

    GET_CLAIM_TYPE_BY_EMPLOYEE: 'GET_CLAIM_TYPE_BY_EMPLOYEE',
    getClaimTypeByEmployee: (detail, obj, callback) => ({ type: actions.GET_CLAIM_TYPE_BY_EMPLOYEE,detail, obj, callback }),

    GET_TRANSPORT_OPTIONS: 'GET_TRANSPORT_OPTIONS',
    UPDATE_TRANSPORT_OPTIONS: 'UPDATE_TRANSPORT_OPTIONS',
    getTransportOptions: () => ({ type: actions.GET_TRANSPORT_OPTIONS }),

    GET_PUBLIC_TRANSPORT_OPTIONS: 'GET_PUBLIC_TRANSPORT_OPTIONS',
    UPDATE_PUBLIC_TRANSPORT_OPTIONS: 'UPDATE_PUBLIC_TRANSPORT_OPTIONS',
    getPublicTransportOptions: () => ({ type: actions.GET_PUBLIC_TRANSPORT_OPTIONS }),

    GET_COMPANIES: 'GET_COMPANIES',
    UPDATE_COMPANIES: 'UPDATE_COMPANIES',
    getCompaniesOptions: () => ({ type: actions.GET_COMPANIES }),

    GET_COMPANIES_BY_ADMIN: 'GET_COMPANIES_BY_ADMIN',
    getCompaniesOptionsByAdmin: () => ({ type: actions.GET_COMPANIES_BY_ADMIN }),

    GET_DIVISIONS: 'GET_DIVISIONS',
    UPDATE_DIVISIONS: 'UPDATE_DIVISIONS',
    getDivisionsOptions: company => ({ type: actions.GET_DIVISIONS, company }),

    GET_ALL_DIVISIONS: 'GET_ALL_DIVISIONS',
    getAllDivisionsOptions: () => ({ type: actions.GET_ALL_DIVISIONS }),

    GET_DIVISIONS_BY_ADMIN: 'GET_DIVISIONS_BY_ADMIN',
    getDivisionsOptionsByAdmin: company => ({ type: actions.GET_DIVISIONS_BY_ADMIN, company }),

    GET_CHECKING_OFFICER_LIST: 'GET_CHECKING_OFFICER_LIST',
    UPDATE_CHECKING_OFFICER_LIST: 'UPDATE_CHECKING_OFFICER_LIST',
    getCheckingOfficerList: detail => ({ type: actions.GET_CHECKING_OFFICER_LIST, detail}),

    GET_APPROVER_LIST: 'GET_APPROVER_LIST',
    UPDATE_APPROVER_LIST: 'UPDATE_APPROVER_LIST',
    getApproverList: detail => ({ type: actions.GET_APPROVER_LIST, detail }),

    GET_DIVISION_HEAD_LIST: 'GET_DIVISION_HEAD_LIST',
    UPDATE_DIVISION_HEAD_LIST: 'UPDATE_DIVISION_HEAD_LIST',
    getDivisionHeadList: detail => ({ type: actions.GET_DIVISION_HEAD_LIST, detail }),

    GET_HR_ROLE_TYPE: 'GET_HR_ROLE_TYPE',
    UPDATE_HR_ROLE_TYPE: 'UPDATE_HR_ROLE_TYPE',
    getHRRoleType: () => ({ type: actions.GET_HR_ROLE_TYPE }),

    GET_HR_COMPANY: 'GET_HR_COMPANY',
    UPDATE_HR_COMPANY: 'UPDATE_HR_COMPANY',
    getHRCompany: (detail, obj, callback) => ({ type: actions.GET_HR_COMPANY, detail, obj, callback  }),

    GET_HR_DIVISION: 'GET_HR_DIVISION',
    UPDATE_HR_DIVISION: 'UPDATE_HR_DIVISION',
    getHRDivision: (detail, obj, callback) => ({ type: actions.GET_HR_DIVISION, detail, obj, callback  }),

    GET_HR_EMPLOYEEID: 'GET_HR_EMPLOYEEID',
    UPDATE_HR_EMPLOYEEID: 'UPDATE_HR_EMPLOYEEID',
    getHREmployeeId: (detail, obj, callback) => ({ type: actions.GET_HR_EMPLOYEEID, detail, obj, callback  }),


    GET_HR_EMPLOYEENAME: 'GET_HR_EMPLOYEENAME',
    UPDATE_HR_EMPLOYEENAME: 'UPDATE_HR_EMPLOYEENAME',
    getHREmployeeName: (detail, obj, callback) => ({ type: actions.GET_HR_EMPLOYEENAME, detail, obj, callback  }),


    GET_HR_LIST: 'GET_HR_LIST',
    UPDATE_HR_LIST: 'UPDATE_HR_LIST',
    getHRList: detail => ({ type: actions.GET_HR_LIST, detail }),

    GET_HEAD_OF_HR: 'GET_HEAD_OF_HR',
    UPDATE_HEAD_OF_HR: 'UPDATE_HEAD_OF_HR',
    getHeadOfHR: () => ({ type: actions.GET_HEAD_OF_HR }),

    GET_EMPLOYEE_LIST: 'GET_EMPLOYEE_LIST',
    UPDATE_EMPLOYEE_LIST: 'UPDATE_EMPLOYEE_LIST',
    getEmployeeList: detail => ({ type: actions.GET_EMPLOYEE_LIST, detail }),

    GET_EMPLOYEE_BY_EEID: 'GET_EMPLOYEE_BY_EEID',
    UPDATE_EMPLOYEE_LIST_BY_EEID:'UPDATE_EMPLOYEE_LIST_BY_EEID',
    getEmployeeByEEID: detail => ({ type: actions.GET_EMPLOYEE_BY_EEID, detail }),

    GET_EMPLOYEE_SETTING_LIST: 'GET_EMPLOYEE_SETTING_LIST',
    UPDATE_EMPLOYEE_SETTING_LIST:'UPDATE_EMPLOYEE_SETTING_LIST',
    getEmployeeSettingList: detail => ({ type: actions.GET_EMPLOYEE_SETTING_LIST, detail }),

    GET_EMPLOYEE_BY_EENAME: 'GET_EMPLOYEE_BY_EENAME',
    UPDATE_EMPLOYEE_BY_EENAME: 'UPDATE_EMPLOYEE_BY_EENAME',
    getEmployeeByEEName: detail => ({ type: actions.GET_EMPLOYEE_BY_EENAME, detail }),

    UPDATE_EMPLOYEE_DETAIL: 'UPDATE_EMPLOYEE_DETAIL',
    updateEmployeeDetail: detail => ({ type: actions.UPDATE_EMPLOYEE_DETAIL, detail }),

    GET_ADMIN_LIST: 'GET_ADMIN_LIST',
    UPDATE_ADMIN_LIST: 'UPDATE_ADMIN_LIST',
    getAdminList: detail => ({ type: actions.GET_ADMIN_LIST, detail }),


    ADD_CHECKING_OFFICER:'ADD_CHECKING_OFFICER',
    addCheckingOfficer: detail => ({ type: actions.ADD_CHECKING_OFFICER, detail }),

    UPDATE_CHECKING_OFFICER: 'UPDATE_CHECKING_OFFICER',
    updateCheckingOfficer: detail => ({ type: actions.UPDATE_CHECKING_OFFICER, detail }),

    DELETE_CHECKING_OFFICER: 'DELETE_CHECKING_OFFICER',
    deleteCheckingOfficer: id => ({ type: actions.DELETE_CHECKING_OFFICER, id }),

    ADD_APPROVER: 'ADD_APPROVER',
    addApprover: detail => ({ type: actions.ADD_APPROVER, detail }),

    UPDATE_APPROVER: 'UPDATE_APPROVER',
    updateApprover: detail => ({ type: actions.UPDATE_APPROVER, detail }),

    DELETE_APPROVER: 'DELETE_APPROVER',
    deleteApprover: id => ({ type: actions.DELETE_APPROVER, id }),

    ADD_DIVISION_HEAD: 'ADD_DIVISION_HEAD',
    addDivisionHead: detail => ({ type: actions.ADD_DIVISION_HEAD, detail }),

    UPDATE_DIVISION_HEAD: 'UPDATE_DIVISION_HEAD',
    updateDivisionHead: detail => ({ type: actions.UPDATE_DIVISION_HEAD, detail }),

    DELETE_DIVISION_HEAD: 'DELETE_DIVISION_HEAD',
    deleteDivisionHead: id => ({ type: actions.DELETE_DIVISION_HEAD, id }),

    ADD_HR: 'ADD_HR',
    addHR: (detail, obj, callback) => ({ type: actions.ADD_HR, detail, obj, callback }),

    DELETE_HR: 'DELETE_HR',
    deleteHR: (id, obj, callback) => ({ type: actions.DELETE_HR, id, obj, callback }),

    ADD_ADMIN: 'ADD_ADMIN',
    addAdmin: (detail, obj, callback) => ({ type: actions.ADD_ADMIN, detail, obj, callback }),

    DELETE_ADMIN: 'DELETE_ADMIN',
    deleteAdmin: (id, obj, callback) => ({ type: actions.DELETE_ADMIN, id, obj, callback }),

    SIGN_IN_ONBEHALF: 'SIGN_IN_ONBEHALF',
    UPDATE_SIGN_IN_ONBEHALF: 'UPDATE_SIGN_IN_ONBEHALF',
    getSignInOnbehalf: detail => ({ type: actions.SIGN_IN_ONBEHALF, detail }),

    REFRESH_SIGN_IN_ONBEHALF: 'REFRESH_SIGN_IN_ONBEHALF',
    refresh_singinonbehalf: () => ({ type: actions.REFRESH_SIGN_IN_ONBEHALF }),

    GET_WELLNESS_OPTIONS: 'GET_WELLNESS_OPTIONS',
    UPDATE_WELLNESS_OPTIONS: 'UPDATE_WELLNESS_OPTIONS',
    getWellnessOptions: () => ({ type: actions.GET_WELLNESS_OPTIONS }),

    GET_NO_PAY_LEAVE_MASTER_OPTIONS: 'GET_NO_PAY_LEAVE_MASTER_OPTIONS',
    UPDATE_NO_PAY_LEAVE_MASTER_OPTIONS: 'UPDATE_NO_PAY_LEAVE_MASTER_OPTIONS',
    intNoPayLeaveMasterOptions: (eeId) => ({ type: actions.GET_NO_PAY_LEAVE_MASTER_OPTIONS, eeId }),  

    GET_ONBEHALF_EMPLOYEE: 'GET_ONBEHALF_EMPLOYEE',
    UPDATE_ONBEHALF_EMPLOYEE: 'UPDATE_ONBEHALF_EMPLOYEE',
    getOnbehalfEmployee: () => ({ type: actions.GET_ONBEHALF_EMPLOYEE }),

    //GET_STATUS_BY_CATEGORY: 'GET_STATUS_BY_CATEGORY',
    //UPDATE_STATUS_OPTIONS: 'UPDATE_STATUS_OPTIONS',
    //getStatusOptions: (type) => ({ type: actions.GET_STATUS_BY_CATEGORY, type }),

    GET_STATUS_BY_CATEGORY: 'GET_STATUS_BY_CATEGORY',
    UPDATE_STATUS_OPTIONS: 'UPDATE_STATUS_OPTIONS',
    getStatusOptions: (statusType) => ({ type: actions.GET_STATUS_BY_CATEGORY, statusType }),

    GET_APPROVER_DELEGATE: 'GET_APPROVER_DELEGATE',
    UPDATE_APPROVER_DELEGATE: 'UPDATE_APPROVER_DELEGATE',
    getApproverDelegate: (approver, form, obj, callback) => ({ type: actions.GET_APPROVER_DELEGATE, approver, form, obj, callback  }),

    GET_DIVISION_HEAD_DELEGATE: 'GET_DIVISION_HEAD_DELEGATE',
    UPDATE_DIVISION_HEAD_DELEGATE: 'UPDATE_DIVISION_HEAD_DELEGATE',
    getDivisionHeadDelegate: (approver, form, obj, callback) => ({ type: actions.GET_DIVISION_HEAD_DELEGATE, approver, form, obj, callback }),
};
export default actions;
