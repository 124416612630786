import axios from 'axios'
import moment from 'moment';
import { call, all, takeEvery, put } from 'redux-saga/effects';
import URL, { localStorageConfig } from '../../settings';
import actions from './actions';



export function* getTypeByTypeName(type) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetTypeByTypeName/' + type, config)

        yield put({
            type: actions.UPDATE_TYPE_BY_TYPE_NAME,
            claimType: data[0].id,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_TYPE_BY_TYPE_NAME, 
            claimType: "",
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}


export function* getStatusOptions({ statusType }) {
        const config = {
            headers: {
                'withCredentials': true,
                'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
            }
        };
        try {
            const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetStatusOptions/' + statusType , config)
            if (data != null) {
                yield put({
                    type: actions.UPDATE_STATUS_OPTIONS,
                    statusOptions: data,
                    loading: false,
                    isError: false,
                    errorMsg: "",
                });
            }
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_STATUS_OPTIONS,
                statusOptions: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

export function* getMasterOptions() {    
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetMasterOption', config)

        yield put({
            type: actions.UPDATE_MASTER_OPTIONS,
            checkingOfficerOptions: data.checkingOfficerOption,
            approverOptions: data.approverOption,
            hrOptions: data.hrOption,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_MASTER_OPTIONS,
            checkingOfficerOptions: [],
            approverOptions: [],
            hrOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getDashboardDetail() {    
   
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'TransportHeader/GetDashboardDetail' , config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_DASHBOARD_DETAIL,
                countSubmittedClaim: data.countSubmittedClaim,
                countPendingCO: data.countPendingCO,
                countPendingApprover: data.countPendingApprover,
                countPendingHR: data.countPendingHR,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DASHBOARD_DETAIL,
            countSubmittedClaim:[],
            countPendingCO: [],
            countPendingApprover: [],
            countPendingHR: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getEmployeeRole({obj, callback }) {    
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Employee/GetEmployeeRole', config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_EMPLOYEE_ROLE,
                isCheckingOfficer: data.isCheckingOfficer,
                isApprover: data.isApprover,
                isHR: data.isHR,
                isAdmin: data.isAdmin,
                isSuperAdmin: data.isSuperAdmin,
                isHRHead : data.isHRHead,
                isHRSharedService : data.isHRSharedService,
                isDivisionHead : data.isDivisionHead,
                isHRBP:data.isHRBP,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        callback(data, obj);
    }
    catch (e) {
        window.location.href = URL.apiUrl + "employee";
       // window.location.href = "http://ehrform.cpgcorp.com.sg/eformws/api/employee"
        yield put({
            type: actions.UPDATE_EMPLOYEE_ROLE,
            isCheckingOfficer: false,
            isApprover: false,
            isHR: false,
            isAdmin:false,
            isSuperAdmin: false,
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getNotification() {    
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Employee/GetUnreadNotification', config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_NOTIFICATION,
                notification: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        window.location.href = URL.apiUrl + "employee";
       // window.location.href = "http://ehrform.cpgcorp.com.sg/eformws/api/employee"
        yield put({
            type: actions.UPDATE_NOTIFICATION,
            notification: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* deleteNotification({ id }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (id != undefined) {
        try {            
            const { data } = yield call(axios.delete, URL.apiUrl + 'Employee/DeleteNotification/' + id, config)

            yield put({
                type: actions.UPDATE_NOTIFICATION,
                notification: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_NOTIFICATION,
                notification: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }
}

export function* getClaimType() {    
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetByCategory/FormType' , config)
        if (data != null) {
            yield put({
                type: actions.UPDATE_CLAIM_TYPE,
                claimTypeOption: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_CLAIM_TYPE,
            claimTypeOption: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getClaimTypeByEmployee( detail ) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetFormTypeByEmployee/' + detail.detail, config)
        if (data != null) {
            yield put({
                type: actions.UPDATE_CLAIM_TYPE,
                claimTypeOption: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }

        detail.callback(data, detail.obj);
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_CLAIM_TYPE,
            claimTypeOption: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}
export function* getTransportOptions() {   
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetTransportOptions', config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_TRANSPORT_OPTIONS,
                transportTypeOptions: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_TRANSPORT_OPTIONS,
            transportTypeOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getPublicTransportOptions() {    
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetPublicTransportOptions', config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_PUBLIC_TRANSPORT_OPTIONS,
                transportModeOptions: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_PUBLIC_TRANSPORT_OPTIONS,
            transportModeOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getCompaniesOptions() {   
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetDistinctCompany', config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_COMPANIES,
                companiesOptions: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_COMPANIES,
            companiesOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getDivisionsOptions(company) {    
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetDistinctDivision/' + company.company, config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_DIVISIONS,
                divisionOptions: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DIVISIONS,
            divisionOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getAllDivisionsOptions() {    
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetDistinctDivision' , config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_DIVISIONS,
                divisionOptions: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DIVISIONS,
            divisionOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getCompaniesByAdminOptions() {
  
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {     
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetDistinctCompanyByAdmin', config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_COMPANIES,
                companiesOptions: data,
                loading: false,
                isError: false,
                isAuthenticate:true,
                errorMsg: "",
            });
        }
    }
    catch (e) {

        const isAuthenticate = e.response.status == 401 ? false : true;
         yield put({
            type: actions.UPDATE_COMPANIES,
             companiesOptions: [],
             isAuthenticate: isAuthenticate,
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getDivisionsByAdminOptions(company) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {       
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetDistinctDivisionByAdmin/' + company.company, config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_DIVISIONS,
                divisionOptions: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DIVISIONS,
            divisionOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getCheckingOfficerList({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
 
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetCheckingOfficer/' + detail.company + "/" + detail.division , config)
        yield put({
            type: actions.UPDATE_CHECKING_OFFICER_LIST,
            checkingOfficerList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_CHECKING_OFFICER_LIST,
            checkingOfficerList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getApproverList({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);    
    const company = detail.company == undefined ? "0" : detail.company;
    const division = detail.division == undefined ? "0" : detail.division;
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetApprover/' + company + "/" + division , config)
        yield put({
            type: actions.UPDATE_APPROVER_LIST,
            approverList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_APPROVER_LIST,
            approverList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getDivisionHeadList({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);    
    const company = detail.company == undefined ? "0" : detail.company;
    const division = detail.division == undefined ? "0" : detail.division;
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetDivisionHead/' + company + "/" + division , config)
        yield put({
            type: actions.UPDATE_DIVISION_HEAD_LIST,
            divisionHeadList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DIVISION_HEAD_LIST,
            divisionHeadList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getHRRoleType() {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetHRRoleType' , config)
        yield put({
            type: actions.UPDATE_HR_ROLE_TYPE,
            HRRolesList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_HR_LIST,
            HRRolesList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getHRCompany({ detail,obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetHRCompany/' + detail.form + "/" + detail.role , config)
        yield put({
            type: actions.UPDATE_HR_COMPANY,
            HRCompany: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
        callback(data, obj);
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_HR_COMPANY,
            HRCompany: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getHRDivision({ detail,obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetHRDivision/' + detail.form + "/" + detail.role + "/" + detail.company , config)
        yield put({
            type: actions.UPDATE_HR_DIVISION,
            HRDivision: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
        callback(data, obj);
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_HR_DIVISION,
            HRDivision: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getHREmployeeId({ detail,obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        //const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetHREmpNo/' + detail.form + "/" + detail.role + "/" + detail.company + "/" + detail.division + "/" + detail.eeName, config)
        const { data } = yield call(axios.post, URL.apiUrl + 'Master/GetHREmpNo' , detail, config)

        yield put({
            type: actions.UPDATE_HR_EMPLOYEEID,
            HREmployeeId: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
        callback(data, obj);
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_HR_EMPLOYEEID,
            HREmployeeId: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getHREmployeeName({ detail,obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        //const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetHREmpName/' + detail.form + "/" + detail.role + "/" + detail.company + "/" + detail.division + "/" + detail.eeID, config)

        const { data } = yield call(axios.post, URL.apiUrl + 'Master/GetHREmpName', detail, config)
        yield put({
            type: actions.UPDATE_HR_EMPLOYEENAME,
            HREmployeeName: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
        callback(data, obj);
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_HR_EMPLOYEENAME,
            HREmployeeName: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getHRList( detail ) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
       // const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetHR/' + detail.formTypeID + "/" + detail.division , config)
        const { data } = yield call(axios.post, URL.apiUrl + 'Master/GetHRList', detail.detail, config)

        yield put({
            type: actions.UPDATE_HR_LIST,
            HRList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_HR_LIST,
            HRList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getHeadOfHR() {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetHeadOfHR' , config)
        yield put({
            type: actions.UPDATE_HEAD_OF_HR,
            HeadOfHR: data[0],
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_HR_LIST,
            HRRolesList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getEmployeeList({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        //const { data } = yield call(axios.get, URL.apiUrl + 'Employee/GetEmployeeByCompanyDivision/' + detail.company + "/" + detail.division, config)

        const { data } = yield call(axios.post, URL.apiUrl + 'Employee/GetEmployeeByCompanyDivision', detail, config)
        yield put({
            type: actions.UPDATE_EMPLOYEE_LIST,
            EmployeeList: data,
            EmployeeListByEEID: data,
            EmployeeListByEEName: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_EMPLOYEE_LIST,
            EmployeeList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getEmployeeByEEID({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Employee/GetEmployeeByEEId/' + detail.eeID, config)
        yield put({
            type: actions.UPDATE_EMPLOYEE_BY_EENAME,
            EmployeeListByEEName: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_EMPLOYEE_BY_EENAME,
            EmployeeListByEEName: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getEmployeeByEEName({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Employee/GetEmployeeByEEName/' + detail.eeName, config)
        yield put({
            type: actions.UPDATE_EMPLOYEE_LIST_BY_EEID,
            EmployeeListByEEID: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_EMPLOYEE_LIST_BY_EEID,
            EmployeeListByEEID: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getEmployeeSettingList({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        //const { data } = yield call(axios.get, URL.apiUrl + 'Employee/GetEmployeeSettingList/' 
        //+ detail.form + "/" + detail.role + "/" + detail.company + "/" + detail.division + "/" + detail.eeID  + "/"
        //    + detail.eeName, config)
        const { data } = yield call(axios.post, URL.apiUrl + 'Employee/GetEmployeeSettingList',detail, config)
        yield put({
            type: actions.UPDATE_EMPLOYEE_SETTING_LIST,
            EmployeeSettingList: data,           
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_EMPLOYEE_SETTING_LIST,
            EmployeeSettingList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getAdminList({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    const emp = localStorage.getItem(localStorageConfig.curUserGUID);
    
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetAdmin/' + detail.company + "/" + detail.division, config)
        yield put({
            type: actions.UPDATE_ADMIN_LIST,
            AdminList: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_ADMIN_LIST,
            AdminList: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* addCheckingOfficer({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {  
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/AddCheckingOfficer', detail, config)
            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_LIST,
                //checkingOfficerList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_LIST,
               // checkingOfficerList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}

export function* updateCheckingOfficer({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/UpdateCheckingOfficer', detail, config)
            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_LIST,
                checkingOfficerList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_LIST,
                // checkingOfficerList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}

export function* deleteCheckingOfficer({ id }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (id != undefined) {
        try {
            
            const { data } = yield call(axios.delete, URL.apiUrl + 'Master/DeleteCheckingOfficer/' + id , config)

            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_LIST,
                checkingOfficerList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_CHECKING_OFFICER_LIST,
                checkingOfficerList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }
}

export function* addApprover({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/AddApprover', detail, config)
            yield put({
                type: actions.UPDATE_APPROVER_LIST,
                approverList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_APPROVER_LIST,
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}

export function* updateApprover({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/UpdateApprover', detail, config)
            yield put({
                type: actions.UPDATE_APPROVER_LIST,
                approverList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_APPROVER_LIST,
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}

export function* deleteApprover({ id }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (id != undefined) {
        try {

            const { data } = yield call(axios.delete, URL.apiUrl + 'Master/DeleteApprover/' + id, config)

            yield put({
                type: actions.UPDATE_APPROVER_LIST,
                approverList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_APPROVER_LIST,
                approverList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }
}

export function* addDivisionHead({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/AddDivisionHead', detail, config)
            yield put({
                type: actions.UPDATE_DIVISION_HEAD_LIST,
                divisionHeadList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_DIVISION_HEAD_LIST,
                divisionHeadList:[],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}

export function* updateDivisionHead({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/UpdateDivisionHead', detail, config)
            yield put({
                type: actions.UPDATE_DIVISION_HEAD_LIST,
                divisionHeadList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_DIVISION_HEAD_LIST,
                divisionHeadList:[],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}


export function* deleteDivisionHead({ id }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (id != undefined) {
        try {
            
            const { data } = yield call(axios.delete, URL.apiUrl + 'Master/DeleteDivisionHead/' + id, config)

            yield put({
                type: actions.UPDATE_DIVISION_HEAD_LIST,
                divisionHeadList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_DIVISION_HEAD_LIST,
                divisionHeadList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }
}

export function* addHR({ detail, obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/AddHR', detail, config)
            yield put({
                type: actions.UPDATE_HR_LIST,
                 HRList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
            callback("", obj);
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_HR_LIST,
                // HRList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}

export function* deleteHR({ id, obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (id != undefined) {
        try {
            
            const { data } = yield call(axios.delete, URL.apiUrl + 'Master/DeleteHR/' + id, config)

            yield put({
                type: actions.UPDATE_HR_LIST,
               // HRList:data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
            callback("", obj);
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_HR_LIST,
                HRList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }
}

export function* addAdmin({ detail, obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/AddAdmin', detail, config)
            yield put({
                type: actions.UPDATE_ADMIN_LIST,
                //AdminList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
            callback("", obj);
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_ADMIN_LIST,
                // HRList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}

export function* deleteAdmin({ id, obj, callback }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (id != undefined) {
        try {
            
            const { data } = yield call(axios.delete, URL.apiUrl + 'Master/DeleteAdmin/' + id, config)

            yield put({
                type: actions.UPDATE_ADMIN_LIST,
                AdminList: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
            callback("", obj);
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_ADMIN_LIST,
                AdminList: [],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }
}


export function* getSignInOnbehalf({ detail }) {   
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Employee/Login', detail, { withCredentials: true })
            localStorage.setItem(localStorageConfig.id_token, data);
            yield put({
                type: actions.UPDATE_SIGN_IN_ONBEHALF,
                isLogin:true,
            });
        }
        catch (e) {
         
        }
    }
}

export function* refreshSignInOnbehalf() {  
    yield put({
                type: actions.UPDATE_SIGN_IN_ONBEHALF,
                isLogin: false,
            });
       
}

export function* getWellnessOptions() {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetWellnessOptions', config)

        if (data != null) {
            yield put({
                type: actions.UPDATE_WELLNESS_OPTIONS,
                wellnessOptions: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_WELLNESS_OPTIONS,
            wellnessOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* intNoPayLeaveMasterOptions({ eeId }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetNoPayLeaveMasterOption/' + localStorage.getItem(localStorageConfig.noPayLeaveID) + "/" + eeId, config)

        yield put({
            type: actions.UPDATE_NO_PAY_LEAVE_MASTER_OPTIONS,
            approverOptions: data.approverOption,
            divisionHeadOption: data.divisionHeadOption,
            employeeName: data.employeeName,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_NO_PAY_LEAVE_MASTER_OPTIONS,
            approverOptions: [],
            divisionHeadOption: [],
            employeeName:"",
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getOnbehalfEmployee() {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetAllEmployee' , config)

        yield put({
            type: actions.UPDATE_ONBEHALF_EMPLOYEE,
            employeeOptions: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_ONBEHALF_EMPLOYEE,
            employeeOptions: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT.",
        });
    }
}

export function* getApproverDelegate({ approver, form, obj, callback  }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetApproverDelegate/' + approver + "/" + form, config)

        yield put({
            type: actions.UPDATE_APPROVER_DELEGATE,
            isApproverDelegate: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
        callback(data, obj);
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_APPROVER_DELEGATE,
            isApproverDelegate: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT2.",
        });
    }
}

export function* getDivisionHeadDelegate({ approver, form, obj, callback  }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    try {
        const { data } = yield call(axios.get, URL.apiUrl + 'Master/GetDivisionHeadDelegate/' + approver + "/" + form, config)

        yield put({
            type: actions.UPDATE_DIVISION_HEAD_DELEGATE,
            isDivisionHeadDelegate: data,
            loading: false,
            isError: false,
            errorMsg: "",
        });
        callback(data, obj);
    }
    catch (e) {
        yield put({
            type: actions.UPDATE_DIVISION_HEAD_DELEGATE,
            isDivisionHeadDelegate: [],
            loading: false,
            isError: true,
            errorMsg: "No connection. Please contact IT1.",
        });
    }
}

export function* updateEmployeeDetail({ detail }) {
    const config = {
        headers: {
            'withCredentials': true,
            'Authorization': `Bearer ${localStorage.getItem(localStorageConfig.id_token)}`
        }
    };
    if (detail != undefined) {
        try {
            const { data } = yield call(axios.post, URL.apiUrl + 'Master/UpdateEmployeeDetail', detail, config)
            yield put({
                type: actions.UPDATE_EMPLOYEE_BY_EENAME,
                EmployeeListByEEName: data,
                loading: false,
                isError: false,
                errorMsg: "",
            });
        }
        catch (e) {
            yield put({
                type: actions.UPDATE_EMPLOYEE_BY_EENAME,
                EmployeeListByEEName:[],
                loading: false,
                isError: true,
                errorMsg: "No connection. Please contact IT.",
            });
        }
    }

}
export default function* rootSaga() {
    yield all([
        yield takeEvery(actions.GET_TYPE_BY_TYPE_NAME, getTypeByTypeName),       
        yield takeEvery(actions.GET_MASTER_OPTIONS, getMasterOptions),  
        yield takeEvery(actions.GET_DASHBOARD_DETAIL, getDashboardDetail),  
        yield takeEvery(actions.GET_EMPLOYEE_ROLE, getEmployeeRole),  
        yield takeEvery(actions.GET_NOTIFICATION, getNotification),  
        yield takeEvery(actions.DELETE_NOTIFICATION, deleteNotification), 
        yield takeEvery(actions.GET_CLAIM_TYPE, getClaimType), 
        yield takeEvery(actions.GET_CLAIM_TYPE_BY_EMPLOYEE, getClaimTypeByEmployee), 
        yield takeEvery(actions.GET_TRANSPORT_OPTIONS, getTransportOptions),
        yield takeEvery(actions.GET_PUBLIC_TRANSPORT_OPTIONS, getPublicTransportOptions),
        yield takeEvery(actions.GET_COMPANIES, getCompaniesOptions),
        yield takeEvery(actions.GET_DIVISIONS, getDivisionsOptions),
        yield takeEvery(actions.GET_ALL_DIVISIONS, getAllDivisionsOptions),
        yield takeEvery(actions.GET_CHECKING_OFFICER_LIST, getCheckingOfficerList),
        yield takeEvery(actions.GET_APPROVER_LIST, getApproverList),
        yield takeEvery(actions.GET_DIVISION_HEAD_LIST, getDivisionHeadList),
        yield takeEvery(actions.GET_HR_ROLE_TYPE, getHRRoleType),
        yield takeEvery(actions.GET_HR_COMPANY, getHRCompany),
        yield takeEvery(actions.GET_HR_DIVISION, getHRDivision),
        yield takeEvery(actions.GET_HR_EMPLOYEEID, getHREmployeeId),
        yield takeEvery(actions.GET_HR_EMPLOYEENAME, getHREmployeeName),
        yield takeEvery(actions.GET_HR_LIST, getHRList),
        yield takeEvery(actions.GET_HEAD_OF_HR, getHeadOfHR),
        yield takeEvery(actions.GET_EMPLOYEE_LIST, getEmployeeList),
        yield takeEvery(actions.GET_EMPLOYEE_BY_EEID, getEmployeeByEEID),
        yield takeEvery(actions.GET_EMPLOYEE_BY_EENAME, getEmployeeByEEName),
        yield takeEvery(actions.GET_EMPLOYEE_SETTING_LIST, getEmployeeSettingList),
        yield takeEvery(actions.GET_ADMIN_LIST, getAdminList),
        yield takeEvery(actions.ADD_CHECKING_OFFICER, addCheckingOfficer),
        yield takeEvery(actions.DELETE_CHECKING_OFFICER, deleteCheckingOfficer),
        yield takeEvery(actions.ADD_APPROVER, addApprover),
        yield takeEvery(actions.DELETE_APPROVER, deleteApprover),
        yield takeEvery(actions.ADD_DIVISION_HEAD, addDivisionHead),
        yield takeEvery(actions.DELETE_DIVISION_HEAD, deleteDivisionHead),
        yield takeEvery(actions.ADD_HR, addHR),
        yield takeEvery(actions.DELETE_HR, deleteHR),
        yield takeEvery(actions.ADD_ADMIN, addAdmin),
        yield takeEvery(actions.DELETE_ADMIN, deleteAdmin),
        yield takeEvery(actions.UPDATE_APPROVER, updateApprover),
        yield takeEvery(actions.UPDATE_DIVISION_HEAD, updateDivisionHead),
        yield takeEvery(actions.UPDATE_CHECKING_OFFICER, updateCheckingOfficer),
        yield takeEvery(actions.GET_COMPANIES_BY_ADMIN, getCompaniesByAdminOptions),
        yield takeEvery(actions.GET_DIVISIONS_BY_ADMIN, getDivisionsByAdminOptions),
        yield takeEvery(actions.SIGN_IN_ONBEHALF, getSignInOnbehalf),
        yield takeEvery(actions.REFRESH_SIGN_IN_ONBEHALF, refreshSignInOnbehalf),

        yield takeEvery(actions.GET_WELLNESS_OPTIONS, getWellnessOptions),
        yield takeEvery(actions.GET_NO_PAY_LEAVE_MASTER_OPTIONS, intNoPayLeaveMasterOptions),
        yield takeEvery(actions.GET_ONBEHALF_EMPLOYEE, getOnbehalfEmployee),

        yield takeEvery(actions.GET_STATUS_BY_CATEGORY, getStatusOptions),  
        yield takeEvery(actions.GET_APPROVER_DELEGATE, getApproverDelegate),  
        yield takeEvery(actions.GET_DIVISION_HEAD_DELEGATE, getDivisionHeadDelegate),  
        yield takeEvery(actions.UPDATE_EMPLOYEE_DETAIL, updateEmployeeDetail),
  ]);
}
