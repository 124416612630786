const actions = {
    GET_NO_PAY_LEAVE_DETAIL: 'GET_NO_PAY_LEAVE_DETAIL',
    UPDATE_NO_PAY_LEAVE_DETAIL: 'UPDATE_NO_PAY_LEAVE_DETAIL',
    getNoPayLeaveByID: (detail, obj, callback) => ({ type: actions.GET_NO_PAY_LEAVE_DETAIL, detail, obj, callback }),

    SUBMIT_LEAVE: 'SUBMIT_LEAVE',
    submitLeave: (leave, obj, callback) => ({ type: actions.SUBMIT_LEAVE, leave, obj, callback }),

    UPDATE_LEAVE: 'UPDATE_LEAVE',
    updateLeave: (leave, obj, callback) => ({ type: actions.UPDATE_LEAVE, leave, obj, callback  }),

    CHECK_AVAILABLE_DATE: 'CHECK_AVAILABLE_DATE',
    UPDATE_CHECK_AVAILABLE_DATE: 'UPDATE_CHECK_AVAILABLE_DATE',
    checkAvailableDate: (eeId) => ({ type: actions.CHECK_AVAILABLE_DATE, eeId }),

    GET_LEAVE_LIST: 'GET_LEAVE_LIST',
    UPDATE_LEAVE_LIST: 'UPDATE_LEAVE_LIST',
    getLeaveList: (leave) => ({ type: actions.GET_LEAVE_LIST, leave }),

    GET_PENDING_APPROVAL_LEAVE_LIST: 'GET_PENDING_APPROVAL_LEAVE_LIST',
    UPDATE_PENDING_APPROVER_LEAVE_LIST: 'UPDATE_PENDING_APPROVER_LEAVE_LIST',
    getPendingApprovalLeaveList: (leave) => ({ type: actions.GET_PENDING_APPROVAL_LEAVE_LIST, leave}),

    GET_LEAVE_BALANCE: 'GET_LEAVE_BALANCE',
    UPDATE_LEAVE_BALANCE: 'UPDATE_LEAVE_BALANCE',
    getLeaveBalance: () => ({ type: actions.GET_LEAVE_BALANCE }),

    CHECK_HRBP_RIGHT: 'CHECK_HRBP_RIGHT',
    UPDATE_HRBP_RIGHT: 'UPDATE_HRBP_RIGHT',
    checkHRBPRight: (leave) => ({ type: actions.CHECK_HRBP_RIGHT, leave }),

};
export default actions;